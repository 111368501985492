import React, { useEffect, useState } from "react";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";

import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { ENTRANCES_BY_STUDENT_ID } from "../../apollo/students";
import { formatDateDash } from "../../common/super";
import Loading from "../../common/Loading";

export default function CardStudent() {
  const { history, match } = useReactRouter();
  const id = match?.params?.id;
  const [data, setData] = useState({});
  const [dataEntrances, setDataEntrances] = useState([]);
  const [studentStatus, setStudentStatus] = useState("");
  const [statusSteps, setStatusSteps] = useState();
  const [getEntranceData, { data: entranceData, loading }] = useLazyQuery(
    ENTRANCES_BY_STUDENT_ID
  );

  useEffect(() => {
    getEntranceData({
      variables: {
        // where: { id: id }
        where: { student: id },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (entranceData && entranceData?.entrances?.data.length > 0) {
      setDataEntrances(entranceData?.entrances?.data);
      setData(entranceData?.entrances?.data[0]?.student);
      for (let i = 0; i < entranceData?.entrances?.data.length; i++) {
        if (entranceData?.entrances?.data[i].status === "APPROVED") {
          setStudentStatus(
            entranceData?.entrances?.data[i].status === "APPROVED"
              ? "APPROVED"
              : entranceData?.entrances?.data[i].status === "REJECTED"
              ? "REJECTED"
              : "REQUESTING"
          );
        } else {
          setStudentStatus(
            entranceData?.entrances?.data[i].status === "APPROVED"
              ? "APPROVED"
              : entranceData?.entrances?.data[i].status === "REJECTED"
              ? "REJECTED"
              : "REQUESTING"
          );
        }
      }
    }
  }, [entranceData]);
  useEffect(() => {
    setStatusSteps(
      studentStatus === "REQUESTING"
        ? true
        : studentStatus === "APPROVED"
        ? true
        : studentStatus === "REJECTED"
        ? false
        : false
    );
  }, [studentStatus]);

  const _clickCard = () => {
    history.push("/studentinfo/" + data?.id, data);
  };
  const _updateStudent = () => {
    history.push("/registrationStudent/" + id, data);
  };

  const _onConvertStatusStep = (status) => {
    let result;
    switch (status) {
      case "REQUESTING":
        result = "ປ້ອນຟອມສຳເລັດ";
        break;
      case "APPROVED":
        result = "ລົງທະບຽນແລ້ວ";
        break;
      case "REJECTED":
        result = "ຖືກປະຕິເສດ";
        break;
      default:
        result = "ປ້ອນຟອມສຳເລັດ";
    }
    return result;
  };

  if (loading) return <Loading />;
  return (
    <div className="container">
      {/* <div className="row mt-5"> */}
      <div className="card">
        <h3 className="mt-5 info-student-header">ຂໍ້​ມູນ​ຜູ້ສະໝັກ</h3>

        {/* <div className="card-header bg-info"> */}
        <div className="card-status-student" />
        <div className="d-flex justify-content-center profile-img">
          <FontAwesomeIcon
            icon={faUserCircle}
            // size="7x"
            className="profile-icon"
          />
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-xs-12 col-md-6 offset-md-3 text-card-student">
              <p>
                ຊື່​ນັກ​ສຶກ​ສາ
                <span className="float-right">
                  {data?.firstNameL} {data?.lastNameL}
                </span>
              </p>

              <p>
                ເລກລະຫັດລົງທະບຽນ
                <span className="float-right">
                  {dataEntrances[0]?.entranceNumber}
                </span>
              </p>

              {/* <p>
              ວັນທີເອົາບັດເຂົ້າຫ້ອງເສັງ
              <span className="float-right">06 - 10/09/2021</span>
            </p> */}
              <p>
                ແກ້​ໄຂ​ຂໍ​້​ມູນ​ລ່າ​ສຸດ
                <span className="float-right">
                  {formatDateDash(dataEntrances[0]?.updatedAt)}
                </span>
              </p>
              <p>
                ສະ​ຖາ​ນະ
                {studentStatus === "REJECTED" ? (
                  <span className="float-right" style={{ color: "#FF7777" }}>
                    {_onConvertStatusStep("REJECTED")}
                  </span>
                ) : (
                  <span className="float-right" style={{ color: "#28a745" }}>
                    {_onConvertStatusStep(
                      dataEntrances && dataEntrances[0]?.status === "APPROVED"
                        ? // || dataEntrances[1].status=="APPROVED"
                          // || dataEntrances[2].status=="APPROVED"
                          "APPROVED"
                        : "REQUESTING"
                    )}
                  </span>
                )}
              </p>
            </div>
            <div className="col-xs-12 col-md-12 link_info_student">
              <label
                style={{ cursor: "pointer" }}
                onClick={() =>
                  studentStatus === "REJECTED" ? _updateStudent() : _clickCard()
                }
              >
                <u>
                  {studentStatus === "REJECTED"
                    ? "ຖືກປະຕິເສດ (ແກ້ໄຂຂໍ້ມູນ)"
                    : "ສະ​ແດງ​ຂໍ​້​ມູນ​ຜູ້ສະໝັກ"}
                </u>
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="card">
        <div className="card-body">

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Stepper orientation="vertical" >
                <Step active={statusSteps}>
                  <StepLabel>
                    <h4 className="text-bold colorblack text-resize3">
                      ຂັ້ນ​ຕອນ​ທີ່ 1 | ລົງ​ທະ​ບຽນປ້ອນ​ຟອມ​ຂໍ້​ມູນ
                    </h4>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ກະລຸນາ, ໄປແຈ້ງຢືນຢັນນຳວິຊາການ ທີ່ສະຖານທີ່ລົງທະບຽນທີ່ທ່ານເລືອກໃນເບື້ອງຕົ້ນ ເພື່ອຮັບເອົາບັດເຂົ້າຄັດເລືອກ ໂດຍການແຈ້ງລະຫັດລົງທະບຽນຄັດເລືອກ.
                        <br />
                        ໃນຊ່ວງວັນທີ 31 ກໍລະກົດ 2023 - 23 ສິງຫາ 2023.
                      </div> */}
      {/* <div style={{ marginTop: 20 }} className="text-resize3">
                      ສະ​ຖາ​ນະ:
                      <span style={{ color: "#28a745", fontWeight: "bold"}}>
                        {" "}
                        ປ້ອນຂໍ້ມູນສຳເລັດ
                      </span>
                    </div> */}
      {/* </Typography>
                  </StepContent>
                </Step>
                <Step active={statusSteps}>
                  <StepLabel>
                    <h4 className="text-bold colorblack text-resize3">
                      ຂັ້ນ​ຕອນ​ທີ່ 2 | ຈ່າຍເງິນຄ່າລົງທະບຽນຄັດເລືອກ
                    </h4>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ກະລຸນາ, ຊຳລະຄ່າລົງທະບຽນເຂົ້າຄັດເລືອກນຳພະນັກງານລົງທະບຽນ.
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus === "APPROVED"}>
                  <StepLabel>
                    <h4 className="text-bold colorblack text-resize3">
                      ຂັ້ນ​ຕອນ​ທີ່ 3 | ແຈ້ງວິ​ຊາ​ການ
                    </h4>
                  </StepLabel>
                  <StepContent active={statusSteps}>
                    <Typography>
                      <div className="text-resize3">
                        ກະລຸນາ, ໄປແຈ້ງໃບຊຳລະຄ່າລົງທະບຽນນຳພະນັກງານລົງທະບຽນ ເພື່ອຢືນຢັນການຊຳລະ.
                        ຈາກນັ້ນ, ແຈ້ງຢືນຢັນການລົງທະບຽນນຳພະນັກງານລົງທະບຽນ ໂດຍໃຊ້ເອກະສານດັ່ງນີ້:
                        <br /><br />
                        * ກໍລະນີເປັນນັກຮຽນຈົບ ມ7 ສົກຮຽນ 2022-2023: <br />
                        1. ບັດເຂົ້າຫ້ອງຄັດເລືອກ ມ7 (ສະບັບແທ້ ຫຼື ສຳເນົົາກໍ່ໄດ້) <br />
                        2. ຮູບຖ່າຍ 3x4 (2ໃບ)
                        <br /><br />
                        * ກໍລະນີເປັນນັກຮຽນທີ່ຈົບ ມ7 ກ່ອນສົກຮຽນ 2022-2023: <br />
                        1. ສຳເນົາໃບປະກາສະນີຍະບັດ ມ7 (ພ້ອມຖືເອກະສານໂຕແທ້ມາຢັ້ງຢືນນຳ) <br />
                        2. ໃບຄະແນນຈົບ ມ7 (ຫຼືທຽບເທົ່າ) <br />
                        2. ຮູບຖ່າຍ 3x4 (2ໃບ)
                        <br /><br />
                        ຈາກນັ້ນ, ກະລຸນາລໍຖ້າຮັບເອົາບັດເຂົ້າຫ້ອງຄັດເລືອກນຳພະນັກງານລົງທະບຽນ.
                      </div> */}
      {/* {statusRA ? (
                      <div className="text-resize3" style={{marginTop: 20}}>
                        ສະ​ຖາ​ນະ:{" "}
                        <span style={{ color: "#28a745", fontWeight: "bold"}}>
                          {" "}
                          ລົງທະບຽນແລ້ວ
                        </span>{" "}
                      </div>
                    ) : (
                      ""
                    )} */}
      {/* </Typography>
                  </StepContent>
                </Step>
                <Step active={studentStatus === "APPROVED"}>
                  <StepLabel>
                    <h4 className="text-bold colorblack text-resize3">
                      ຂັ້ນ​ຕອນ​ທີ່ 4 | ສໍາເລັດການລົງທະບຽນເຂົ້າເສັງ
                    </h4>
                  </StepLabel>
                  <StepContent active={studentStatus === "APPROVED"}>
                    <Typography>
                      <div className="text-resize3">
                        ທ່ານໄດ້ສຳເລັດການລົງທະບຽນຄັດເລືອກເຂົ້າເປັນນັກຮຽນໃໝ່ທີ່ ມະຫາວິທະຍາໄລຈຳປາສັກ.
                        <br />
                        ກະລຸນາຖືບັດເຂົ້າຫ້ອງຄັດເລືອກຂອງທ່ານ ໄປຍື່ນເພື່ອເຂົ້າເສັງຕົວຈິງ ໃນວັນທີ 24 ສິງຫາ 2023
                        <br /><br />
                        + ສະຖານທີ່ຄັດເລືອກ ແລະ ເບີໂທຕິດຕໍ່ຂອງແຕ່ລະຈຸດຄັດເລືອກມີດັ່ງນີ້: <br />
                        ແຂວງຈຳປາສັກ: ມະຫາວິທະຍາໄລ ຈຳປາສັກ (020 2226 5099, 020 5505 4595)<br />
                        ແຂວງສາລະວັນ: ມັດທະຍາສົມບູນແຂວງ (020 2835 0480)<br />
                        ແຂວງເຊກອງ: ມັດທະຍົມຕອນປາຍແຂວງ (020 7822 7789)<br />
                        ແຂວງອັດຕະປື: ມັດທະຍົມສົມບູນກິນນອນ-ຊົນເຜົ່າ (020 9773 2777)
                      </div>
                    </Typography>
                  </StepContent>
                </Step>
              </Stepper>
            </div>
          </div> */}

      {/* <h5>
          <u>ຂໍ້ແນະນຳ</u>
        </h5>
        <label>
        ເອກະສານປະກອບສະໝັກຄັດເລືອກ:
        </label>
        <label>
          - ບັດເຂົ້າຫ້ອງຄັດເລືອກຈົບຊັ້ນ ມ.7 (ສຳເນົາຄັດຕິດ), ສຳຫຼັບຜູ້ທີ່ຈົບໃນສົກຮຽນກ່ອນໜ້ານີ້ແມ່ນໃຫ້ສຳເນົາໃບປະກາດ ມ.7 ແລະ ໃບຄະແນນຈົບ ມ.7 (ຫຼືທຽບເທົ່າ, ສຳເນົາປື້ມຕິດຕາມຊັ້ນມັດທະຍົມຕອນປາຍ (ມ5, ມ6 ແລະ ມ7) ແລະ ພ້ອມຮູບ 3x4 ຈຳນວນ 2 ໃບ.
        </label>
        <br></br>
        <label>
          - ໃຫ້ນຳເອົາລະຫັດລົງທະບຽນຄັດເລືອກ​ຂ້າງ​ເທິງ​
          ມາຢັ້ງຢືນເພື່ອຮັບບັດເຂົ້າຫ້ອງເສັງ.
        </label> */}
      {/* </div>
      </div> */}
      {/* </div> */}
    </div>
  );
}
