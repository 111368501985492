import React from 'react'
import useReactRouter from 'use-react-router'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Row, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import {formatTime,formatDateDash} from '../../common/super';

export default function SuccessPayment () {
  const { location } = useReactRouter()
  const student = location?.state
  return (
    <div className='container-fluid' style={{ height: '100vh' }}>
      <Card style={{  border: "none", color: "#6F6F6F" }}>
      <h2 style={{padding: 20}}>ເບິ່ງ​ຂໍ້​ມູນ​ທີ່​ຊຳ​ລະ​ແລ້ວ</h2>
        <Row>
        <Col sm="4"></Col>
          <Card style={{border: "none", width: 590, marginBottom:40, boxShadow: "0px 3px 6px #00000029" }}>
            <Card.Body>
              <Row>
                <Col sm='12' style={{ color: '#00B033', textAlign: 'center' }}>
                  <FontAwesomeIcon icon={faCheckCircle} size='4x' />
                </Col>
                <Col sm='12'
                  style={{
                    color: '#00B033',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 23,
                    padding: 12
                  }}
                >
                  ​ສຳ​ເລັດ​ການ​ຊຳ​ລະ​ເງິນ
                </Col>
                <Col sm='12' style={{ textAlign: 'center' }}>
                {formatDateDash(student?.payment?.createdAt)}
                </Col>
                <Col sm='12' style={{ textAlign: 'center', padding: 8 }}>
                {formatTime(student?.payment?.createdAt)}
                </Col>
                <Col
                  sm='12'
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                >
                  ຂໍ້​ມຸນ​ນັກ​ສຶກ​ສາ
                </Col>
                <Row>
                  <Col sm='7'>ລະ​ຫັດ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm='5'>{student?.studentNumber}</Col>
                  <hr style={{color: "#d8d7d7"}} />
                  <Col sm='7'>ຊື່ ແລະ ນາມສະກຸນສັກສືກສາ</Col>
                  <Col sm='5'>{student?.firstNameL} {student?.lastNameL}</Col>
                  <hr style={{color: "#d8d7d7"}} />
                  <Col sm='7' style={{marginTop: -8}}>ເບີໂທ</Col>
                  <Col sm='5' style={{marginTop: -8}}>{student?.phoneMobile}</Col>
                </Row>
                <Col
                  sm='12'
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                >
                  ຂໍ້​ມຸນ​ການ​ຊຳ​ລະ​ເງິນ
                </Col>
                <Row>
                  <Col sm='7'>ປະ​ເພດ​ການ​​ຊຳ​ລະ</Col>
                  <Col sm='5'>Onepay</Col>
                  <hr style={{color: "#d8d7d7"}} />
                  <Col sm='7'>ຊື່ບັນຊີຜູ້ໂອນ</Col>
                  <Col sm='5'>{student?.payment?.paymentName}</Col>
                  {/* <hr />
                  <Col sm='7'>ເລກບັນຊີຜູ້ໂອນ</Col>
                  <Col sm='5'></Col> */}
                  <hr style={{color: "#d8d7d7"}} />
                  {/* <Col sm='7'>ທະນາຄານ</Col>
                  <Col sm='5'></Col>
                  <hr /> */}
                  <Col sm='7' style={{fontWeight: "bold"}}>ຈຳນວນເງີນ</Col>
                  <Col sm='5' style={{fontWeight: "bold"}}>{student?.payment?.amount} {student?.payment?.ccy}</Col>
                  <hr style={{color: "#d8d7d7"}} />
                  <Col sm='7'>ຄໍາອະທິບາຍ</Col>
                  <Col sm='5' style={{fontSize: 12}}>{student?.payment?.description}</Col>
                  <hr style={{color: "#d8d7d7"}} />
                  <Col sm='7'>ເລກໃບບິນ</Col>
                  <Col sm='5'>{student?.payment?.paymentId}</Col>
                </Row>
              </Row>
            </Card.Body>
          </Card>
          </Row>
      </Card>
    </div>
  )
}
