import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import styled from "styled-components";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../page/login/login";
import FormLogin from "../page/login/form_login";
// Navbar
import CustomNavbar from "../common/customNavbar";
import RegistrationStudent from "../page/registration/RegistrationStudent";
import RegistrationConfirm from "../page/registration/RegistrationConfirm";
import CardStudent from "../page/registration/CardStudent";
import StudentInfo from "../page/registration/StudentInfo";
import PresentATemporaryStudentCard from "../page/registration/PresentATemporaryStudentCard";
import QcodePresentATemporaryStudentCard from "../page/registration/QRcodePresentAtemporaryStudentCard";
import TuitionPaymentChannels from "../page/registration/TuitionPaymentChannels";
import Onepay from "../page/registration/Onepay";
import PaymentSuccess from "../page/payment/success"
import SuccessPayment from "../page/payment/SuccessPayment"
import PaymentFail from "../page/payment/error"


const Main = styled.main` 
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0px;
  margin-left: ${ (props) => (props.expanded ? 0 : 0) }px;
`;
function Routes() {
  
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />
        <PublicRoute exact path="/login-from/:phoneMobile" component={FormLogin} />

        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              {/* <CustomSideNav location={location} history={history} /> */}

              <Main>
                {/* navbar */}
                <CustomNavbar />

                {/* Contents */}
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    marginTop: 80,
                  }}
                >
                  
                  {/* private routes */}
                  <PrivateRoute
                    path="/registrationStudent"
                    exact
                    component={(props) => <RegistrationStudent />}
                  />
                  <PrivateRoute
                    path="/registrationStudent/:id"
                    exact
                    component={(props) => <RegistrationStudent />}
                  />
                  <PrivateRoute
                    path="/RegistrationConfirm"
                    exact
                    component={(props) => <RegistrationConfirm />}
                  />
                  <PrivateRoute
                    path="/cardstudent/:id"
                    exact
                    component={(props) => <CardStudent />}
                  />
                  <PrivateRoute
                    path="/studentinfo/:id"
                    exact
                    component={(props) => <StudentInfo />}
                  />
                  <PrivateRoute
                    path="/presentatemporarystudentcard"
                    exact
                    component={(props) => <PresentATemporaryStudentCard />}
                  />
                  <PrivateRoute
                    path="/qrcodepresentatemporarystudentcard"
                    exact
                    component={(props) => <QcodePresentATemporaryStudentCard />}
                  />
                  <PrivateRoute
                    path="/tuitionpaymentchannels"
                    exact
                    component={(props) => <TuitionPaymentChannels />}
                  />
                  <PrivateRoute
                    path="/onepay"
                    exact
                    component={(props) => <Onepay />}
                  />
                  <PrivateRoute
                    path="/payment/success"
                    exact
                    component={(props) => <PaymentSuccess />}
                  />
                  <PrivateRoute
                    path="/payment/successpayment"
                    exact
                    component={(props) => <SuccessPayment />}
                  />
                  <PrivateRoute
                    path="/payment/fail"
                    exact
                    component={(props) => <PaymentFail />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
