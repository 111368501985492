import React, { useState } from 'react'
import {Col, Modal } from 'react-bootstrap'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomButton from '../common/CustomButton'
import logo from '../images/mojor_logo.png'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

function CustomNavbar () {
  const { history } = useReactRouter()
  const [show, setShow] = useState(false)

  const _onLogout = () => {
    history.push(`/`)
    localStorage.removeItem('USER_ID')
    localStorage.removeItem('USER_PHONE')
    window.location.reload(true)
  }
  return (
    <div>
      <nav className='navbar fixed-top navbar-light bg-light shadow-sm  mb-0 bg-white rounded'>
        <div className='navbar-band text-center' style={{paddingLeft: 10}}>
          <img
            src={logo}
            alt=""
            className='nav-image'
            style={{ width: 30, height: 40 }}
          />
          <p className='title-band'>
          ມະຫາວິທະຍາໄລ ຈຳປາສັກ <br/>
          Champasak University
          </p>
        </div>
        <span className='navbar-text logout' onClick={() => setShow(true)}>
          <span className='textLogout'>ອອກຈາກລະບົບ</span> &nbsp;
          <FontAwesomeIcon
            icon={faSignOutAlt}
            size='1x'
            color="#057CAE"
          />
        </span>
      </nav>

      <Modal
        style={{ marginTop: 50 }}
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span style={{ color: '#057CAE', fontWeight: 'bold' }}>
              ອອກຈາກລະບົບ
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col
            className='text-center'
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 24,
                color: '#057CAE'
              }}
            >
              ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ?
            </div>
          </Col>
          <Col className='d-flex justify-content-between'>
            <div></div>
            <CustomButton
              onClick={() => setShow(false)}
              type='submit'
              title='ຍົກເລີກ'
            />

            <CustomButton
              confirm
              onClick={() => _onLogout()}
              type='submit'
              title='ຢືນຢັນ'
            />
            <div></div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default CustomNavbar
