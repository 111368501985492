import { gql } from "apollo-boost";

export const FACULTIES = gql`
  query Faculties($where: facultyWhereInput) {
    faculties(where: $where) {
      data {
        id
        name
        group
        departments{
          id
          name
      }
     }
    }
  }
`;

export const CREATE_ENTRANCE = gql`
  mutation CreateEntrance($data: EntranceCreateInput!) {
    createEntrance(data: $data){
      id
    }
  }
`;

export const UPDATE_ENTRANCE = gql`
  mutation UpdateEntrance($data:  EntranceCreateInput!, $where: EntranceWhereUniqueInput!) {
    updateEntrance(data: $data, where:$where){
      id
    }
  }
`;
export const UPDATE_STUDENT_STATUS = gql`
  mutation UpdateStudentStatus($data: StudentStatusInput!,$where: StudentStatusWhereInput!) {
    updateStudentStatus(data: $data,where:$where){
      id
    }
  }
`;

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String, $fileName: String) {
    preSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      url
    }
  }
`