import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import nuolimage from "../../images/mojor.jpg";
import logo from "../../images/mojor_logo.png";
import pngkit_call from "../../images/pngkit_call.png";
import { Col, Row, Modal } from "react-bootstrap";

import useReactRouter from "use-react-router";
import { faSignInAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import consts from "../../consts";
export default function Login() {
  const { history } = useReactRouter();
  const [showModalHelper, setShowModalHelper] = useState(false);
  const [showModalCloseEntrance, setShowModalCloseEntrance] = useState(false);
  const [year, setYear] = useState("");

  const _handleCloseModalEntrance = () => setShowModalCloseEntrance(false);
  const _handleClickInplan = () => {
    // open registration
    // history.push("/login-from/registration");

    //close registration
    setShowModalCloseEntrance(true);
  };

  useEffect(() => {
    const y = new Date().getFullYear();
    const assessmentYear = y + " - " + (y + 1);
    setYear(assessmentYear);
  }, []);

  return (
    <div>
      <div
        className="container-fluid m-0 d-inline d-none d-sm-block"
        style={{ paddingLeft: 0 }}
      >
        <div className="row p-0 m-0">
          <div className="col-md-6 cardCustom p-0">
            <img src={nuolimage} className="img" alt="" />
            <div className="bg-color-image"></div>

            <div className="textdev">
              <img
                src={logo}
                className="logo"
                style={{ height: 120, width: 90 }}
                alt=""
              />
              <div className="textfont-size16 colorWhite">
                ມະຫາວິທະຍາໄລ ຈຳປາສັກ <br />
                Champasak University
              </div>
              <div className="colorWhite paddings textfont-size45">
                ຍິນ​ດີ​ຕ້ອນ​ຮັບ​ເຂົ້າ​ສູ່​ເວັບ​ໄຊ​ທ໌
                <br /> ການ​ລົງ​ທະ​ບຽນຄັດເລືອກເຂົ້າຮຽນ ມຈ
              </div>
            </div>
          </div>
          <div
            className="col-md-6"
            style={{ backgroundColor: "#ffffff", padding: 0 }}
          >
            <div
              className="textfont-size20"
              style={{ textAlign: "right", marginRight: 23, marginTop: 24 }}
            >
              ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{" "}
              <span
                style={{
                  color: "#0d6efd",
                  textDecorationColor: "#0d6efd",
                  textDecorationLine: "underline",
                }}
                onClick={() => setShowModalHelper(true)}
              >
                ຊ່ວຍ​ເຫຼືອ
              </span>
              <div className="row" style={{ marginTop: 40 }}>
                <div
                  className="col-md-12"
                  style={{ fontSize: 28, textAlign: "center" }}
                >
                  <p>ສະໝັກລົງ​ທະ​ບຽນຄັດເລືອກເຂົ້າຮຽນ ມຈ</p>
                  <p>ສົກຮຽນ {year}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{ textAlign: "start", paddingLeft: "40px" }}
              >
                <p>ເປີດສະໝັກອອນໄລວັນທີ</p>
              </div>
              <div className="col-6">
                <span style={{ color: "red" }}>22 ກໍລະກົດ - 21 ສິງຫາ 2024</span>
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{ textAlign: "start", paddingLeft: "40px" }}
              >
                <p>ເປີດຮັບເອກະສານ</p>
              </div>
              <div className="col-6">
                <span style={{ color: "red" }}>22 ກໍລະກົດ - 21 ສິງຫາ 2024</span>
              </div>
            </div>
            <div className="row">
              <div
                className="col-6"
                style={{ textAlign: "start", paddingLeft: "40px" }}
              >
                <p>ວັນທີຄັດເລືອກ</p>
              </div>
              <div className="col-6">
                <span style={{ color: "red" }}>20 ສິງຫາ 2024</span>
              </div>
            </div>
            <div className="apply-css" onClick={() => _handleClickInplan()}>
              ລົງທະບຽນຄັດເລືອກ
              <span className="apply-icon">
                <FontAwesomeIcon icon={faSignInAlt} />
              </span>
            </div>
            {/* <a
              href="https://cu-bucket-files.s3.ap-southeast-1.amazonaws.com/files/announcement.pdf"
              target='bank'
            >
              <div className="button-css">
                ແຈ້ງການສະໝັກຄັດເລືອກເຂົ້າຮຽນ ມຈ
              </div>
            </a> */}

            {/* <p style={{ textAlign: "center", fontWeight: "600" }}>
              ຖ້າທ່ານໃດສົນໃຈຕິດຕໍ່ສອບຖາມຂໍ້ມູນເພີ່ມໄດ້ທີ່:
            </p>

            <p style={{ textAlign: "center", marginBottom: 0 }}>
              ເບີໂທ: 020 2241 1994
            </p>
            <p style={{ textAlign: "center" }}>ເບີໂທ: 020 5821 5555</p>

            <p style={{ textAlign: "center", color: "red" }}>
              ໃນໂມງລັດຖະການແມ່ນ ວັນຈັນ-ວັນສຸກ ເວລາ 8:00 - 16:00
            </p> */}

            <div className="apply_footer">
              <div className="text-center">
                ລິຂະສິດ
                <br /> Copyright © {new Date().getFullYear()} Champasak
                University.
                <br /> All Rights Reserved.
              </div>
              <div
                className="text-center"
                style={{ color: "#000", fontSize: 11 }}
              >
                {consts.VERSION}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-sm-none" style={{ display: "flex" }}>
        <div className="cardCustom">
          <img src={nuolimage} className="img" alt="" />
          <div className="bg-color-image"></div>
          <div className="textdev">
            <img
              src={logo}
              className="logo"
              style={{ height: 120, width: 70 }}
              alt=""
            />
            <Col style={{ color: "#fff" }}>
              ສະໝັກລົງ​ທະ​ບຽນຄັດເລືອກເຂົ້າຮຽນ ມຈ
            </Col>
            <Col style={{ color: "#fff" }}>ສົກຮຽນ {year}</Col>
            <br />
            <div className="row">
              <div className="col-6">
                <p
                  style={{ float: "left", paddingLeft: "20px", color: "#fff" }}
                >
                  ເປີດສະໝັກອອນໄລວັນທີ
                </p>
              </div>
              <div
                className="col-6"
                style={{ textAlign: "start", paddingRight: "20px" }}
              >
                <span style={{ color: "#fff" }}>
                  22 ກໍລະກົດ - 21 ສິງຫາ 2024
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p
                  style={{ float: "left", paddingLeft: "20px", color: "#fff" }}
                >
                  ເປີດຮັບເອກະສານ
                </p>
              </div>
              <div
                className="col-6"
                style={{ textAlign: "start", paddingRight: "20px" }}
              >
                <span style={{ color: "#fff" }}>
                  22 ກໍລະກົດ - 21 ສິງຫາ 2024
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p
                  style={{ float: "left", paddingLeft: "20px", color: "#fff" }}
                >
                  ວັນທີຄັດເລືອກ
                </p>
              </div>
              <div
                className="col-6"
                style={{ textAlign: "start", paddingRight: "20px" }}
              >
                <span style={{ color: "#fff" }}>23 ສິງຫາ 2024</span>
              </div>
            </div>
            <div className="apply-css" onClick={() => _handleClickInplan()}>
              ລົງທະບຽນຄັດເລືອກ
              <span className="apply-icon">
                <FontAwesomeIcon icon={faSignInAlt} />
              </span>
            </div>
            {/* <a
              style={{ backgroundColor: "red" }}
              href="https://cu-bucket-files.s3.ap-southeast-1.amazonaws.com/files/announcement.pdf"
              target='bank'
            >
              <div className="button-css">
                ແຈ້ງການສະໝັກຄັດເລືອກ
              </div>
            </a> */}

            {/* <p
              style={{
                textAlign: "center",
                fontWeight: "200",
                marginBottom: 0,
              }}
              className="contactUs"
            >
              ຖ້າທ່ານໃດສົນໃຈຕິດຕໍ່ສອບຖາມຂໍ້ມູນເພີ່ມໄດ້ທີ່:
            </p>

            <p
              className="contactUs"
              style={{ textAlign: "center", marginBottom: 0 }}
            >
              ເບີໂທ: 020 2241 1994
            </p>
            <p className="contactUs" style={{ textAlign: "center" }}>
              ເບີໂທ: 020 5821 5555
            </p>

            <p
              className="contactUs"
              style={{ textAlign: "center", color: "red", marginBottom: 0 }}
            >
              ໃນໂມງລັດຖະການແມ່ນ ວັນຈັນ-ວັນສຸກ ເວລາ 8:00 - 16:00
            </p> */}

            <div
              className="text-center Copyright"
              style={{ paddingTop: 10, color: "#fff", marginBottom: 0 }}
            >
              ລິຂະສິດ
              <br /> Copyright © {new Date().getFullYear()} Champasak University
              <br /> All Rights Reserved.
            </div>
            <div style={{ color: "#fff", fontSize: 11 }}>{consts.VERSION}</div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalHelper}
        onHide={() => setShowModalHelper(false)}
        // animation={false}
      >
        <Modal.Header className="borderModalLogin">
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "#707070",
            fontSize: 18,
            fontWeight: "bold",
            // paddingLeft: 40,
          }}
          className="bodyHelp"
        >
          <Row className="paddingHelp">
            <Col sm="12" style={{ textAlign: "center", marginBottom: 10 }}>
              ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              ເບີ​ໂທ​ລະ​ສັບ:
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              031 258 999
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              ອີ​ເມວ:
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              slmscuoffice@gmail.com
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <img
                src={pngkit_call}
                alt=""
                style={{ width: 150, height: 100 }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showModalCloseEntrance}
        onHide={_handleCloseModalEntrance}
        centered
      >
        <Modal.Body>
          <Col className="text-center text-info p-5">
            <FontAwesomeIcon icon={faInfoCircle} size="9x" />
            <div className="textfont-size42 text-bold mt-5">
              {/* ປິດການລົງທະບຽນແລ້ວ */}
              ຍັງບໍ່ເປີດໃຫ້ລົງທະບຽນ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}
