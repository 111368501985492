import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useReactRouter from "use-react-router";
import "./index.css";
import { formatDateDash } from "../../common/super";
import Loading from "../../common/Loading";
import { ENTRANCES_BY_STUDENT_ID } from "../../apollo/students";
import { useLazyQuery } from "@apollo/react-hooks";
import consts from "../../consts";

export default function StudentInfo() {
  const { history, match } = useReactRouter();
  const id = match?.params?.id;
  const [user, setUser] = useState({});
  const [applies, setApplies] = useState([]);
  const [comingFrom, setComingFrom] = useState({});
  const [previewImageURL, setPreviewImageURL] = useState();

  const [getEntranceData, { data, loading }] = useLazyQuery(
    ENTRANCES_BY_STUDENT_ID,
    { fetchPolicy: "network-only" }
  );

  useEffect(() => {
    getEntranceData({
      variables: {
        where: { student: id },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.entrances?.data) {
      setUser(data?.entrances?.data[0]?.student);
      setApplies(data?.entrances?.data);
      setComingFrom(data?.entrances?.data[0]?.comingFrom);
      setPreviewImageURL(
        consts.S3_IMAGE + data?.entrances?.data[0]?.student?.image
      );
    }
  }, [data]);

  const _onConvertGender = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "MONK":
        result = "ຄູບາ";
        break;
      case "NOVICE":
        result = "ສໍາມະເນນ";
        break;
      default:
        result = "ຊາຍ";
    }
    return result;
  };

  const _onConvertRegistrationAt = (status) => {
    let result;
    switch (status) {
      case "MORJOR":
        result = "ມຈ";
        break;
      case "SALAVANH":
        result = "ແຂວງສາລະວັນ";
        break;
      case "ATTAPEU":
        result = "ແຂວງອັດຕະປື";
        break;
      case "SEKONG":
        result = "ແຂວງເຊກອງ";
        break;
      default:
        result = "-";
    }
    return result;
  };

  if (loading) return <Loading />;
  return (
    <div className="container">
      <div className="Nav-infoStudent">
        <span
          onClick={() => history.push("/cardstudent/" + id)}
          style={{ color: "#0099FF", cursor: "pointer" }}
        >
          {" "}
          ໜ້າຫຼັກ{" "}
        </span>{" "}
        / ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
      </div>
      <div className="card card-detial-student">
        <div className="card-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-4 borderRight">
              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
              </p>
              {data?.entrances?.data[0]?.student?.image ? (
                <div className="browserImage" style={{ margin: "0 auto" }}>
                  <img alt="" src={previewImageURL} className="image-preview" />
                </div>
              ) : (
                <img
                  style={{ margin: "0 auto" }}
                  alt=""
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                  }
                  className="browserImage"
                />
              )}
              <p className="text-entrance">
                ເລກລະຫັດລົງທະບຽນ
                <span className="entranceNumber">
                  {data?.entrances?.data[0]?.entranceNumber ?? "-"}
                </span>
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 borderRight">
              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ຂໍ້ມູນ​ທົ່ວໄປ
              </p>

              <p className="textStudent">
                ຊື່
                <span className="detail">
                  {user?.gender === "MALE"
                    ? "ທ້າວ "
                    : user?.gender === "MONK"
                    ? "ຄູບາ"
                    : user?.gender === "NOVICE"
                    ? "ສໍາມະເນນ"
                    : "ນາງ "}{" "}
                  {user?.firstNameL ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ນາມສະກຸນ
                <span className="detail">{user?.lastNameL ?? "-"}</span>
              </p>

              {/* <p className="textStudent">
                ຊື່ (ພາສາອັງກິດ)
                <span className="detail">
                  {user?.gender === "MALE" ? "Mr. " : user?.gender === "MONK" ? "Monk " :  user?.gender === "NOVICE" ? "Novice " : "Ms. "}
                  {user?.firstNameE ?? "-"}
                </span>
              </p>

              <p className="textStudent">
                ນາມສະກຸນ (ພາສາອັງກິດ)
                <span className="detail">{user?.lastNameE ?? "-"}</span>
              </p> */}
              <p className="textStudent">
                ວັນເດືອນປີເກີດ
                <span className="detail">
                  {formatDateDash(user?.birthday, "D M Y") ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ເພດ
                <span className="detail">
                  {user && user ? _onConvertGender(user?.gender) : "-"}
                </span>
              </p>
              {/* <p className="textStudent">
                ສະຖານະ
                <span className="detail">
                  {user?.maritalStatus !== "SINGLE" ? "ແຕ່ງງານແລ້ວ" : "ໂສດ"}
                </span>
              </p> */}
              <p className="textStudent">
                ສັນຊາດ
                <span className="detail">{user?.nationality ?? "-"}</span>
              </p>
              <p className="textStudent">
                ຊົນເຜົ່າ
                <span className="detail">
                  {data?.entrances?.data[0]?.tribe?.name ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ເບີໂທລະສັບມືຖື
                <span className="detail">{user?.phoneMobile ?? "-"}</span>
              </p>

              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ຂໍ້​ມູນ​ໂຮງ​ຮຽນ​ທີ່​ຈົບ
              </p>

              <p className="textStudent">
                ຈົບຈາກແຂວງ
                <span className="detail">
                  {comingFrom?.graduatedProvince ?? "-"}
                </span>
              </p>

              <p className="textStudent">
                ຈົບຈາກໂຮງຮຽນ
                <span className="detail">
                  {comingFrom?.graduatedSchool ?? "-"}
                </span>
              </p>

              <p className="textStudent">
                ສົກສຶກສາ
                <span className="detail">
                  {comingFrom?.graduatedAccessMentYear ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ຄະແນນລວມ
                <span className="detail">
                  {user?.highSchoolSummaryScore ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ຄະແນນສະເລ່ຍ
                <span className="detail">
                  {user?.highSchoolAverageScore ?? "-"}
                </span>
              </p>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              {/* <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ
              </p>
              <p className="textStudent">
                ແຂວງ
                <span className="detail">{user?.address?.province ?? "-"}</span>
              </p>
              <p className="textStudent">
                ເມືອງ
                <span className="detail">{user?.address?.district ?? "-"}</span>
              </p>
              <p className="textStudent">
                ບ້ານ
                <span className="detail">{user?.address?.village ?? "-"}</span>
              </p>

              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ສະຖານທີ່ເກີດ
              </p>

              <p className="textStudent">
                ແຂວງ
                <span className="detail">
                  {user?.birthAddress?.province ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ເມືອງ
                <span className="detail">
                  {user?.birthAddress?.district ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ບ້ານ
                <span className="detail">
                  {user?.birthAddress?.village ?? "-"}
                </span>
              </p>

              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ກໍລະນີສຸກເສີນຕິດຕໍ່
              </p>
              <p className="textStudent">
                ພົວພັນທ່ານ
                <span className="detail">
                  {user?.emergencyContact?.contactName ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ເບີໂທຕິດຕໍ່
                <span className="detail">
                  {user?.emergencyContact?.emergencyPhone ?? "-"}
                </span>
              </p>
              <p className="textStudent">
                ອາໄສຢູ່ຮ່ວມກັນ
                <span className="detail">
                  {user?.emergencyContact?.isLiveTogether === false
                    ? "ບໍ່ແມ່ນ"
                    : "ແມ່ນ"}
                </span>
              </p> */}
              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ຄະແນນວິຊາສອບເສັງຕ່າງໆ
              </p>

              <p>
                <table
                  className="table  table-bordered"
                  style={{ marginTop: "0px" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: "20px" }}>ລຳດັບ</th>
                      <th>ວິຊາ</th>
                      <th className="text-end">ຄະແນນ</th>
                    </tr>
                  </thead>
                  {user?.highSchoolSubjects?.map((item, index) => (
                    <tbody>
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.subject}</td>
                        <td className="text-end">{item.score}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </p>
              {user?.highSchoolScoreImage ? (
                <>
                  <p
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    ຮູບໃບຄະແນນ
                    <span className="detail">
                      <img
                        alt=""
                        src={consts.S3_IMAGE + user?.highSchoolScoreImage}
                        style={{
                          width: "100px",
                          border: "2px solid grey",
                        }}
                        className="mt-2"
                      />
                    </span>
                  </p>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="row border-top">
            <div className="col-xs-12 col-sm-6 col-md-6 borderRight">
              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ເລືອກ​ສະ​ໝັກ ສາຂາຕົ້ນຕໍ
              </p>

              <p className="textStudent">
                ລະດັບ
                <span className="detail">
                  {applies?.length > 0
                    ? applies?.find((itm) => itm.applyNumber == 1)?.level
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ກຸ່ມ
                <span className="detail">
                  {applies?.length > 0
                    ? applies?.find((itm) => itm.applyNumber == 1)?.group
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ຄະນະ
                <span className="detail">
                  {applies?.length > 0
                    ? applies?.find((itm) => itm.applyNumber == 1)?.faculty
                        ?.name
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ສາຂາວິຊາ
                <span className="detail">
                  {applies?.length > 0
                    ? applies?.find((itm) => itm.applyNumber == 1)?.major?.name
                    : "-"}
                </span>
              </p>

              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ເລືອກ​ສະ​ໝັກ ສາຂາຮອງ 1
              </p>

              <p className="textStudent">
                ລະດັບ
                <span className="detail">
                  {applies?.length > 1
                    ? applies?.find((itm) => itm.applyNumber == 2)?.level
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ກຸ່ມ
                <span className="detail">
                  {applies?.length > 1
                    ? applies?.find((itm) => itm.applyNumber == 2)?.group
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ຄະນະ
                <span className="detail">
                  {applies?.length > 1
                    ? applies?.find((itm) => itm.applyNumber == 2)?.faculty
                        ?.name
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ສາຂາວິຊາ
                <span className="detail">
                  {applies?.length > 1
                    ? applies?.find((itm) => itm.applyNumber == 2)?.major?.name
                    : "-"}
                </span>
              </p>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6 ">
              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ເລືອກ​ສະ​ໝັກ ສາຂາຮອງ 2
              </p>

              <p className="textStudent">
                ລະດັບ
                <span className="detail">
                  {applies?.length > 2
                    ? applies?.find((itm) => itm.applyNumber == 3)?.level
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ກຸ່ມ
                <span className="detail">
                  {applies?.length > 2
                    ? applies?.find((itm) => itm.applyNumber == 3)?.group
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ຄະນະ
                <span className="detail">
                  {applies?.length > 2
                    ? applies?.find((itm) => itm.applyNumber == 3)?.faculty
                        ?.name
                    : "-"}
                </span>
              </p>
              <p className="textStudent">
                ສາຂາວິຊາ
                <span className="detail">
                  {applies?.length > 2
                    ? applies?.find((itm) => itm.applyNumber == 3)?.major?.name
                    : "-"}
                </span>
              </p>

              {/* <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ສະຖານທີ່ຄັດເລືອກ
              </p>

              <p className="textStudent">
                ສະຖານທີ່ຄັດເລືອກ
                <span className="detail">
                  {data?.entrances?.data[0]?.registrationAt ? 
                  _onConvertRegistrationAt(data?.entrances?.data[0]?.registrationAt) : "-"}
                </span>
              </p> */}

              <p style={{ fontSize: 20, color: "#057CAE", fontWeight: "bold" }}>
                ຊຳລະຄ່າທຳນຽມ
              </p>

              <p className="textStudent">
                ປະເພດຊຳລະ
                <span className="detail">
                  {user?.payment == "TRANSFER"
                    ? "ໂອນຜ່ານທະນາຄານ"
                    : "ຈ່າຍເງິນສົດ"}
                </span>
              </p>

              {user?.payment == "TRANSFER" ? (
                <>
                  <p
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    ຮູບຫຼັກຖານໂອນເງິນ
                    <span className="detail">
                      <img
                        alt=""
                        src={consts.S3_IMAGE + user?.registerFeeImage}
                        style={{
                          width: "100px",
                          border: "2px solid grey",
                        }}
                        className="mt-2"
                      />
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-xs-6 col-md-6 col-xl-6">
                      <span className="textStudent">ເລກທີ່ໃບບິນ</span>
                    </div>
                    <div className="col-xs-6 col-md-6 col-xl-6">
                      <p className="detail">{user?.registerBillNo || "-"}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
