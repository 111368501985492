import React from 'react'
import { Route } from 'react-router-dom'

// custom import
import { useAuth } from '../helpers/user'
// import Header from '../components/Header'

/**
 *
 * return authenticated header & component
 */
function PrivateRoute({ component: Component, headerTitle, ...rest }) {
  const isAuthenticated = useAuth()

  // console.log("isAuthenticated", isAuthenticated)
  // if not authenticated, redirect to "/"
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated
          ? <div>
            {/* <Header isAuthenticated headerTitle={headerTitle} /> */}
            <Component {...props} />
          </div>
          : <Component {...props} />
          // : <Redirect to='/' />
      }
    />
  )
}

export default PrivateRoute
