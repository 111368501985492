
import moment from "moment";
// import Notiflix from "notiflix-react";


// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
    let resp = moment(dashDate).format("DD/MM/YYYY");
    return resp;
};
export const formatDateYYMMDD = (dashDate) => {
    let resp = moment(dashDate).format("YYYY-MM-DD");
    return resp;
};

export const formatDateTime = (dateTime) => {
    moment.locale("lo");
    let resp = moment(dateTime).format("DD MMMM YYYY, HH:mm");
    return resp;
  };
  
export const formatTime = (dashDate) => {
    let resp = moment(dashDate).format("HH:mm:ss");
    return resp;
};