import React, { useEffect, useState } from 'react'
import { Col, Row, Card } from "react-bootstrap";
import useReactRouter from 'use-react-router';
import { v4 as uuidv4 } from 'uuid';
import { OnePay } from "../../onepay";
import imgOnplay from '../../images/oneplay.jpg';
import { useMutation } from '@apollo/react-hooks'
import {CREATE_PAYMENT} from '../../apollo/payment';
import {UPDATE_STUDENT_STATUS } from "../../apollo/registrationstudent";

export default function Onepay() {

    const { history } = useReactRouter();
    const codeId = history?.location?.state?.code
    const [code, setCode] = useState("");
    const [isShowCode, setIsShowCode] = useState(false);
    const [isShowSuccessIcon, setIsShowSuccessIcon] = useState(false);
    const [mcid, setMcid] = useState();  // merchant id
    const [amount, setAmount] = useState();  // invoice amount
    const [description, setDescription] = useState("");  // must define as English text
    const [ccy, setCcy] = useState();  // currency LAK
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");

    const [createPayment] = useMutation(CREATE_PAYMENT)
    const [updateStudentStatus] = useMutation(UPDATE_STUDENT_STATUS)
    const transactionid = uuidv4(); // transaction id ((please define as unique key))
    const data = { mcid, ccy, country, province}
    let onePay = new OnePay(data); // create new OnePay instance
    useEffect(() => {
        _getQRCode();
        _subcribeQRCode();
        setMcid("mch5c2f0404102fb");
        setAmount(1);
        setDescription("Test NUOL OnePay by QR Code");
        setCcy(418);
        setCountry("LA");
        setProvince("VTE");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* get OnePay QR code */
    const _getQRCode = () => {
        onePay.getCode({
            transactionid: transactionid,
            amount: amount,
            description: description,
        }, function (_code) {
            if (_code && _code !== "") {
                setIsShowCode(true)
                setIsShowSuccessIcon(false)
                setCode(_code)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
   
    /* subscribe to receiving OnePay callback*/
    const _subcribeQRCode = async () => {
        onePay.subscribe(transactionid, async (res) => {
            if (res.uuid === transactionid) {
                 try{
                 await createPayment({variables: {data: {
                    paymentId: res?.id,
                    mcId: res?.mcId,
                    ccy: res?.ccy,
                    merchantName: res?.merchantName,
                    paymentName: res?.name,
                    phone: res?.phone,
                    amount: res?.amount,
                    description: res?.description,
                    
                }}});
                 updateStudentStatus({
                     variables: {
                         data: {statusStep:  "APPROVED"
                     },
                         where: { phoneMobile: codeId }
                     }
                 })
                 setIsShowSuccessIcon(true);
                 setIsShowCode(false);
                 setTimeout(() => {
                     history.push(`/cardstudent/${codeId}`)
                     window.location.reload()
                 }, 2000);
                 // setIsShowCode(false)
 
            }catch(err){
                alert("ສ້າງ onepay ສຳເລັດ")
            }
              
            }
        });
    }
    const onClick = () => {
        const uri = 'onepay://qr/' + code;
        window.location.href = uri;
    }

    return (
        <div className="container" style={{ height: "100vh" }}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col style={{ display: "flex", justifyContent: "center", flexDirection: "row", marginBottom: 50 }}>
                            <Card>
                                <Card.Body className="cardOneplay">
                                    <Col style={{ fontSize: 29, fontWeight: "bold" }}>
                                        ຊ່ອງ​ທາງ​ການ​ຊຳ​ລະ​ເງິນ​ດ້ວຍ Onepay
                                    </Col>
                                    <Col className="oneplayBorder">
                                        <div><img src={imgOnplay} alt=""/></div>
                                        {isShowSuccessIcon && <img alt="" src="https://www.freeiconspng.com/uploads/success-icon-10.png"
                                            style={{ width: 200, height: 200, marginTop: 35, marginBottom: 35 }} />}
                                        {isShowCode && <img alt="" onClick={() => onClick()} className="qr-code"
                                            src={'https://chart.googleapis.com/chart?chs=343x343&cht=qr&chl=' + code + '&choe=UTF-8'} />}
                                    </Col>
                                    <Col style={{ fontSize: 29, fontWeight: "bold" }}>
                                        ກ​ະ​ລຸ​ນາ, ສະ​ແກນ​ເພື່ອ​ຊຳ​ລ​ະ​ເງິນ
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
