import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Modal, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

function RegistrationConfirm({
  param,
  id,
  onConfirm,
  isDisabled,
  showAddConfirmModal,
  _handleShowAddConfirmModalClose
}) {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
    if(isDisabled) {
      setIsLoading(true)
    }else {
      setIsLoading(false)
    }
  }, [isDisabled])

  const _onConvertGender = gender => {
    let result
    switch (gender) {
      case 'MALE':
        result = 'ຊາຍ'
        break
      case 'FEMALE':
        result = 'ຍິງ'
        break
      case 'MONK':
        result = 'ຄູບາ'
        break
      case 'NOVICE':
        result = 'ສໍາມະເນນ'
        break
      default:
        result = 'ຊາຍ'
        // do nothing
    }
    return result
  }
  const _onConvertMaritalStatus = maritalStatus => {
    let result
    switch (maritalStatus) {
      case 'SINGLE':
        result = 'ໂສດ'
        break
      case 'MARRIAGE':
        result = 'ແຕ່ງງານແລ້ວ'
        break
        default:
        // do nothing
    }
    return result
  }
  return (
    <Modal
      style={{ marginTop: 50 }}
      show={showAddConfirmModal}
      onHide={_handleShowAddConfirmModalClose}
      size="xl"
      centered
    >
      <Modal.Header closeButton={isDisabled ? false : true}>
        <Col
          style={{
            marginTop: 20,
            fontSize: 30,
            fontWeight: "bold",
            color: "#057CAE",
          }}
        >
          {id ? "ຢືນ​ຢັນ​ການແກ້ໄຂຂໍ້​ມູນ" : "ຢືນ​ຢັນ​ການສົ່ງ​ຂໍ້​ມູນ"}
        </Col>
      </Modal.Header>
      <Modal.Body>
        {isLoading ?
          <div className="d-flex justify-content-center align-items-center"
            style={{height: "40vh"}}
          >
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          </div>
          :
          <>
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນ​ທົ່ວໄປ
            </li>
            <li className="list-group-item">
              ຊື່: <span className="float-right">{param?.firstNameL ?? ""}</span>
            </li>
            <li className="list-group-item">
              ນາມ​ສະ​ກຸນ:{" "}
              <span className="float-right">{param?.lastNameL ?? ""}</span>
            </li>
            {/* <li className="list-group-item">
              ຊື່ (​ພາ​ສາ​ອັງ​ກິດ):{" "}
              <span className="float-right">{param?.firstNameE ?? ""}</span>
            </li>
            <li className="list-group-item">
              ນາມ​ສະ​ກຸນ (​ພາ​ສາ​ອັງ​ກິດ):{" "}
              <span className="float-right">{param?.lastNameE ?? ""}</span>
            </li> */}
            <li className="list-group-item">
              ວັນ​ເດືອນ​ປີ​ເກີດ:{" "}
              <span className="float-right">{param?.birthday ?? ""}</span>
            </li>
            <li className="list-group-item">
              ເພດ:{" "}
              <span className="float-right">
                {_onConvertGender(param?.gender) ?? ""}
              </span>
            </li>
            {/* <li className="list-group-item">
              ສະ​ຖາ​ນະ:{" "}
              <span className="float-right">
                {_onConvertMaritalStatus(param?.maritalStatus) ?? ""}
              </span>
            </li> */}

            <li className="list-group-item">
              ສັນ​ຊາດ:
              <span className="float-right">{param?.nationality ?? ""}</span>
            </li>

            <li className="list-group-item">
              ຊົນ​ເຜົ່າ:
              {/* {console.log("hhhh", param)} */}
              <span className="float-right">{param?.tribeName ?? ""}</span>
            </li>

            <li className="list-group-item">
              ສາ​ສະ​ໜາ:
              <span className="float-right">{param?.religion ?? ""}</span>
            </li>

            {/* <li className="list-group-item">
              ເບີໂທເຮືອນ:
              <span className="float-right">{param?.phoneHome ?? ""}</span>
            </li>
            <li className="list-group-item">
              ເບີໂທລະສັບມືຖື:
              <span className="float-right">{param?.phoneMobile ?? ""}</span>
            </li>
            <li className="list-group-item">
              ອີ່ເມວ:
              <span className="float-right">{param?.email ?? ""}</span>
            </li> */}
          </ul>
          {/* <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ທີ່ຢູ່ປະຈຸບັນ
            </li>
            <li className="list-group-item">
              ແຂວງ:{" "}
              <span className="float-right">
                {param?.address?.province ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ເມືອງ:{" "}
              <span className="float-right">
                {param?.address?.district ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ບ້ານ:{" "}
              <span className="float-right">{param?.address?.village ?? ""}</span>
            </li>
          </ul> */}
          {/* <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ສະຖານທີເກີດ
            </li>
            <li className="list-group-item">
              ແຂວງ:{" "}
              <span className="float-right">
                {param?.birthAddress?.province ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ເມືອງ:{" "}
              <span className="float-right">
                {param?.birthAddress?.district ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ບ້ານ:{" "}
              <span className="float-right">
                {param?.birthAddress?.village ?? ""}
              </span>
            </li>
          </ul> */}
          <ul className="list-group list-group-flush">
            <li
              className="list-group-item bg-active"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              <FontAwesomeIcon icon={faCaretDown} /> ຂໍ້ມູນໂຮງຮຽນທີ່ຈົບ
            </li>
            <li className="list-group-item">
              ຈົບຈາກແຂວງ:
              <span className="float-right">
                {param?.comingFrom?.graduatedProvince ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ຈົບຈາກເມືອງ:
              <span className="float-right">
                {param?.comingFrom?.graduatedDistrict ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ຈົບຈາກໂຮງຮຽນ:
              <span className="float-right">
                {param?.comingFrom?.graduatedSchool ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ສົກສືກສາ:
              <span className="float-right">
                {param?.comingFrom?.graduatedAccessMentYear ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ຄະແນນລວມ:
              <span className="float-right">
                {param?.highSchoolSummaryScore ?? ""}
              </span>
            </li>
            <li className="list-group-item">
              ຄະແນນສະເລ່ຍ:
              <span className="float-right">
                {param?.highSchoolAverageScore ?? ""}
              </span>
            </li>
          </ul>
          <div className="button-confirm">
            <center>
              <button
                className="btn btn-outline-primary btn-lg button-apply-confirm"
                type="submit"
                onClick={() => _handleShowAddConfirmModalClose()}
              >
                ຍົກເລີກ
              </button>

              <button
                className="btn btn-default btn-lg bg-active button-apply-confirm"
                type="submit"
                disabled={isDisabled}
                style={{color:"#fff"}}
                onClick={() => {
                  onConfirm()
                  setIsLoading(true)
                  }
                }
              >
                ຢືນຢັນ
              </button>
            </center>
          </div>
          </>
        }
      </Modal.Body>
    </Modal>
  )
}

export default RegistrationConfirm
