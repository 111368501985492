import { gql } from 'apollo-boost'
export const STUDENTS = gql`
  query Students( $where: studentWhereInput
    $skip: Int
    $limit: Int
    $orderBy: OrderByInput
    ) {
      students(
      where: $where 
      skip: $skip 
      limit: $limit 
      orderBy: $orderBy 
    ) {
      data {
        id
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        gender
        maritalStatus
        birthday
        nationality
        religion
        phoneHome
        phoneMobile
        email
        tribe {
          id
          name
        }
        parentName
        entranceNumber
        emergencyContact{
          contactName
          isLiveTogether
          emergencyPhone
          explainNotToStayTogether
        }
        address{
          province
          district
          village
        }
        birthAddress{
          province
          district
          village
        }
        createdAt
      }
    }
  }
`

export const STUDENT = gql`
  query Student($where: StudentWhereUniqueInput!) {
    student(where: $where) {
      id
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        gender
        maritalStatus
        birthday
        nationality
        religion
        phoneHome
        phoneMobile
        email
        tribe {
          id
          name
        }
        parentName
        emergencyContact{
          contactName
          isLiveTogether
          emergencyPhone
          explainNotToStayTogether
        }
        address{
          province
          district
          village
        }
        birthAddress{
          province
          district
          village
        }
        createdAt
    }
  }
`
export const ENTRANCE = gql`
  query Entrance($where: EntranceWhereUniqueInput!) {
    entrance(where: $where) {
      student{
        id
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        gender
        maritalStatus
        birthday
        nationality
        phoneHome
        religion
        phoneMobile
        email
        image
        parentName
        emergencyContact{
          contactName
          isLiveTogether
          emergencyPhone
          explainNotToStayTogether
        }
        address{
          province
          district
          village
        }
        birthAddress{
          province
          district
          village
        }
        createdAt
      }
      registrationAt
      tribe {
        id
        name
      }
      applies{
        applyNumber
        group
        level
        faculty{
          id
          name
        }
        department{
          id
          name
        }
      }
      comingFrom{
        graduatedProvince
        graduatedSchool
        graduatedDistrict
        graduatedAccessMentYear
      }
      entranceNumber
      status
    }
  }
`
export const ENTRANCES = gql`
  query Entrances(
      $where: EntranceWhereInput
      $skip: Int
      $limit: Int
      $orderBy: OrderByInput) {
    entrances(
      where: $where
      skip: $skip
      limit: $limit
      orderBy: $orderBy){
        data{
          id
          student{
            id
            firstNameL
            lastNameL
            firstNameE
            lastNameE
            gender
            image
            maritalStatus
            birthday
            nationality
            phoneHome
            religion
            phoneMobile
            email
            highSchoolSummaryScore
            highSchoolAverageScore
            highSchoolScoreImage
            highSchoolSubjects {
              subject
              score
              isMain
            }
            payment
            registerFee
            registerBillNo
            registerFeeImage
            parentName
            emergencyContact{
              contactName
              isLiveTogether
              emergencyPhone
              explainNotToStayTogether
            }
            address{
              province
              district
              village
            }
            birthAddress{
              province
              district
              village
            }
          createdAt
        }

        registrationAt
        tribe {
          id
          name
        }
        applies{
          applyNumber
          group
          level
          faculty{
            id
            name
          }
          department{
            id
            name
          }
        }
        comingFrom{
          graduatedProvince
          graduatedSchool
          graduatedDistrict
          graduatedAccessMentYear
        }
        entranceNumber
        applyNumber
        status
        group
        level
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major {
          id
          name
          code
        }
      }
    }
  }
`

export const ENTRANCES_BY_STUDENT_ID = gql`
  query Entrances(
      $where: EntranceWhereInput
      $skip: Int
      $limit: Int
      $orderBy: OrderByInput) {
    entrances(
      where: $where
      skip: $skip
      limit: $limit
      orderBy: $orderBy){
        data{
          id
          tribe {
            id
            name
          }
          faculty{
            id
            name
          }
          department{
            id
            name
          }
          major {
            id
            name
            code
          }
          student{
            id
            firstNameL
            lastNameL
            firstNameE
            lastNameE
            gender
            maritalStatus
            birthday
            nationality
            phoneHome
            religion
            phoneMobile
            email
            image
            highSchoolSummaryScore
            highSchoolAverageScore
            highSchoolScoreImage
            highSchoolSubjects {
              subject
              score
              isMain
            }
            payment
            registerFee
            registerBillNo
            registerFeeImage
            parentName
            emergencyContact{
              contactName
              isLiveTogether
              emergencyPhone
              explainNotToStayTogether
            }
            address{
              province
              district
              village
            }
            birthAddress{
              province
              district
              village
            }
          createdAt
        }
        registrationAt
        comingFrom{
          graduatedProvince
          graduatedDistrict
          graduatedSchool
          graduatedAccessMentYear
        }
        applyNumber
        group
        level
        entranceNumber
        status
      }
    }
  }
`

export const TRIBES = gql`
  query Tribes($where: TribeWhereInput, $skip: Int, $limit: Int, $orderBy: OrderByInput) {
    tribes(where: $where, skip: $skip, limit: $limit, orderBy: $orderBy) {
      total
      data {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`

export const DEPARTMENTS = gql`
  query Departments(
    $where: DepartmentWhereInput
    $skip: Int
    $limit: Int
    $orderBy: OrderByInput
  ) {
    departments(
      where: $where
      skip: $skip
      limit: $limit
      orderBy: $orderBy
    ) {
      total
      data {
        id
        name
        enName
        description
        enDescription
        status
        faculty {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const MAJORS = gql`
  query Majors(
    $where: MajorWhereInput
    $skip: Int
    $limit: Int
    $orderBy: OrderByInput
  ) {
    majors(
      where: $where
      skip: $skip
      limit: $limit
      orderBy: $orderBy
    ) {
      total
      data {
        id
        name
        code
        faculty {
          id
          name
        }
        department {
          id
          name
        }
        group
        createdAt
        updatedAt
      }
    }
  }
`
