module.exports = {
  PRIMARY_COLOR: '#057CAE',
  SECONDARY_COLOR: '#057CAE',
  FONT_COLOR_PRIMARY: '#383838',
  FONT_COLOR_SECONDARY: '#6f6f6f',
  CONTAINER_PADDING: 24,
  CONTAINER_MARGIN_TOP: -10,
  BORDER_COLOR: '#7BB500',
  BORDER_COLOR_DELETE: '#B80000',
  DELETE_COLOR_BUTTON: '#C7C7C7',
  USER_ID: 'USER_ID',
  USER_KEY: 'USER',
  USER_PHONE: 'USER_PHONE',
  USER_DATA_KEY: 'USER_DATA',
  VERSION: 'V 1.0.3',
  S3_IMAGE: "https://cu-bucket-files.s3.ap-southeast-1.amazonaws.com/entrance-image/"
}
