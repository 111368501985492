import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/mojor_logo.png";
import pngkit_call from "../../images/pngkit_call.png";
import { useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import { USER_PHONE, USER_ID } from "../../consts/index";
import { Button, Row, Col, Modal } from "react-bootstrap";

import { faSignInAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ENTRANCES_BY_STUDENT_ID } from "../../apollo/students";

function Form_login() {
  const { history } = useReactRouter();
  const [depositedAmount, setDepositedAmount] = useState("");
  const [countNumber, setCountNumber] = useState();
  const [textInput, setTextInput] = useState("");
  const [showModalHelper, setShowModalHelper] = useState(false);

  const [Phone, setPhone] = useState("");
  const [showModalCloseEntrance, setShowModalCloseEntrance] = useState(false);
  const [loadDataEntrance, { data: entranceData }] = useLazyQuery(
    ENTRANCES_BY_STUDENT_ID
  );

  const _handleCloseModalEntrance = () => setShowModalCloseEntrance(false);

  useEffect(() => {
    if (entranceData) {
      if (countNumber === 8) {
        if (entranceData?.entrances?.data?.length > 0) {
          onLoginSuccess();
        } else {
          history.push("/registrationStudent");
        }
      } else {
        setTextInput("ກະລຸນາ ປ້ອນເບີໂທໃຫ້ຄົບ 8 ຕົວເລກ!");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entranceData]);

  const _onEnterKey = (e) => {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  };
  const _handleSubmit = async () => {
    // ປິດລົງທະບຽນ
    setShowModalCloseEntrance(true);

    // ເປີດໃຫ້ລົງທະບຽນ
    /* await localStorage.setItem(USER_PHONE, JSON.stringify(Phone));
    loadDataEntrance({
      variables: {
        where: { phoneMobile: Phone },
      },
    }); */
  };

  const onLoginSuccess = async () => {
    await localStorage.setItem(
      USER_ID,
      JSON.stringify(entranceData?.entrances?.data[0]?.student?.id)
    );
    history.push(
      `/cardstudent/${entranceData?.entrances?.data[0]?.student?.id}`
    );
  };

  const handleDepositeAmountChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

    setPhone("+85620" + evt.target.value);
    if (rx_live.test(evt.target.value)) {
      setCountNumber(evt.target.selectionStart);
      setDepositedAmount(evt.target.value);
    }
  };

  return (
    <div style={{ background: "#F5F5F5", height: "100vh" }}>
      <div className="row p-0 m-0 justify-content-center">
        <p className="login-help">
          ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?{" "}
          <span
            style={{
              color: "#0d6efd",
              textDecorationColor: "#0d6efd",
              textDecorationLine: "underline",
            }}
            onClick={() => setShowModalHelper(true)}
          >
            ຊ່ວຍ​ເຫຼືອ
          </span>
        </p>
        <img
          className="logo"
          alt=""
          src={logo}
          style={{ width: 90, height: 110 }}
        />

        <p className="login-head">ມະຫາວິທະຍາໄລ ຈຳປາສັກ</p>
      </div>

      <div className="card card_login">
        <div className="card-body">
          <p className="login-title">
            <b style={{ fontSize: 22 }}>ລັອກອິນເພື່ອເຂົ້າໃຊ້ລະບົບ</b>
          </p>
          <p className="login-small-title" style={{ fontSize: 15 }}>
            ລັອກອິນຜ່ານເບີໂທລະສັບຂອງທ່ານ
          </p>
          <div>
            <div className="input-group mb-3">
              <div className="input-group-prepend border-0">
                <span
                  className="input-group-text border-0"
                  style={{ padding: 10, borderRadius: 0 }}
                  id="basic-addon1"
                >
                  +85620
                </span>
              </div>
              <input
                type="tel"
                className="form-control"
                maxLength={8}
                pattern="[+-]?\d+(?:[.,]\d+)?"
                onChange={handleDepositeAmountChange}
                value={depositedAmount}
                placeholder="xxxx-xxxx"
                onKeyDown={(e) => _onEnterKey(e)}
              />
            </div>
            <span
              className="login-error"
              style={{ color: "red", fontSize: 12 }}
            >
              {textInput}
            </span>
          </div>
          <Button className="button-login" onClick={() => _handleSubmit()}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  fontSize: 20,
                }}
              >
                ລ໊ອກອິນ
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: 30,
                  alignItems: "flex-end",
                }}
              >
                <FontAwesomeIcon
                  icon={faSignInAlt}
                  style={{ fontSize: 24, fontWeight: "normal" }}
                  color="#fff"
                />
              </div>
            </div>
          </Button>
          <div className="textfont-size18 login-remark">
            <div className="text-center">
              <b>
                <u>ໝາຍເຫດ</u>
              </b>
            </div>
            <div className="textfont-size15 p-3">
              1. ກະລຸນາປ້ອນເບີໂທລະສັບຂອງທ່ານໃຫ້ຄົບ 8 ຕົວເລກເພື່ອເຂົ້າສູ່ລະບົບ.
              <br />
              2.
              ປ້ອນເບີໂທລະສັບທີ່ທ່ານເຄີຍໃຊ້ລົງທະບຽນເພື່ອກວດຂໍ້ມູນທີ່ລົງທະບຽນສຳເລັດແລ້ວ.
              <br />
              3. ເບີໂທລະສັບ 1 ເບີສາມາດລົງທະບຽນໄດ້ 1 ຄັ້ງເທົ່ານັ້ນ.
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModalHelper}
        onHide={() => setShowModalHelper(false)}
        // animation={false}
      >
        <Modal.Header className="borderModalLogin">
          <div>ຕິດ​ຕໍ່​ພວກ​ເຮົາ</div>
        </Modal.Header>
        <Modal.Body
          style={{
            color: "#707070",
            fontSize: 18,
            fontWeight: "bold",
            // paddingLeft: 40,
          }}
          className="bodyHelp"
        >
          <Row className="paddingHelp">
            <Col sm="12" style={{ textAlign: "center", marginBottom: 10 }}>
              ຊ່ອງ​ທາງ​ໃນ​ການຕິດ​ຕໍ່​​ພວກ​ເຮົາ​
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              ເບີ​ໂທ​ລະ​ສັບ:
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              031 258 999
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              ອີ​ເມວ:
            </Col>
            <Col xs="6" sm="6" className="contactHelp">
              slmscuoffice@gmail.com
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <img
                src={pngkit_call}
                alt=""
                style={{ width: 150, height: 100 }}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalCloseEntrance}
        onHide={_handleCloseModalEntrance}
        centered
      >
        <Modal.Body>
          <Col className="text-center text-info p-5">
            <FontAwesomeIcon icon={faInfoCircle} size="9x" />
            <div className="textfont-size42 text-bold mt-5">
              ປິດການລົງທະບຽນແລ້ວ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Form_login;
