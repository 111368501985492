import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faCheckCircle,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import swal from "@sweetalert/with-react";
import _, { isEmpty } from "lodash";
import {
  Col,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";

import {
  FACULTIES,
  CREATE_ENTRANCE,
  UPDATE_ENTRANCE,
  PRE_SIGNED_URL,
} from "../../apollo/registrationstudent";
import { TRIBES, ENTRANCES, MAJORS } from "../../apollo/students";
import { LAO_ADDRESS } from "../../consts/Address";
import RegistrationConfirm from "./RegistrationConfirm";
import "bootstrap/dist/css/bootstrap.min.css";
import Loading from "../../common/Loading";
import axios from "axios";
import consts from "../../consts";

export default function RegistrationStudent() {
  const { history, match } = useReactRouter();
  const NOW = new Date().getFullYear();
  const assessmentYear = "2023 - 2024"; // NOW + " - " + (parseInt(NOW) + 1);
  const inputImage = useRef("inputImage");

  const phoneData = localStorage.getItem("USER_PHONE");
  const [localstoragePhone, setLocalstoragePhone] = useState();
  const studentType = match.params.studenttype;
  const id = match?.params?.id ?? null;

  const [coverImage, setCoverImage] = useState();
  const [previewImageURL, setPreviewImageURL] = useState();
  const [student, setStudent] = useState({});
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
  const [selectProvinceIndex, setSelectProvinceIndex] = useState();
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState();
  const [graduatedYear, setGraduatedYear] = useState([]);
  const [formParam, setFormParam] = useState({});
  const [show, setShow] = useState(false);
  const [majors, setMajors] = useState();
  const [tribeName, setTribeName] = useState("");
  const [faculties, setFaculties] = useState([]);
  const [selectRegisters, setSelectRegisters] = useState([
    {
      level: "ປະລິນຍາຕີ",
      group: "",
      applyNumber: 1,
      faculty: "",
      facultyName: "",
      department: "",
      departmentName: "",
      major: "",
      majorName: "",
      majorCode: "",
    },
    {
      level: "ປະລິນຍາຕີ",
      group: "",
      applyNumber: 2,
      faculty: "",
      facultyName: "",
      department: "",
      departmentName: "",
      major: "",
      majorName: "",
      majorCode: "",
    },
    {
      level: "ປະລິນຍາຕີ",
      group: "",
      applyNumber: 3,
      faculty: "",
      facultyName: "",
      department: "",
      departmentName: "",
      major: "",
      majorName: "",
      majorCode: "",
    },
  ]);
  const [level, setLevel] = useState("ປະລິນຍາຕີ");
  const [group, setGroup] = useState("");
  const [registrationAt, setRegistrationAt] = useState("");
  const [facultyName, setFacultyName] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [majorId, setMajorId] = useState("");
  const [majorName, setMajorName] = useState("");
  const [majorCode, setMajorCode] = useState("");
  const [errorValidate, setErrorValidate] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const scoreImage = useRef("scoreImage");
  const [scoreSheetTarget, setScoreSheetTarget] = useState({
    file: null,
    previewView: null,
  });
  const subjs = [
    {
      subject: "ພາສາລາວ-ວັນນະຄະດີ",
      score: "",
      isMain: true,
    },
    {
      subject: "ຄະນິດສາດ",
      score: "",
      isMain: true,
    },
    {
      subject: "ກຸ່ມວິທະຍາສາດທຳມະຊາດ",
      score: "",
      isMain: true,
    },
    {
      subject: "ກຸ່ມວິທະຍາສາດສັງຄົມ",
      score: "",
      isMain: true,
    },
    {
      subject: "ພາສາອັງກິດ",
      score: "",
      isMain: true,
    },
  ];
  const [highSchoolSubjects, setHighSchoolSubject] = useState(subjs);
  const [payBy, setPayBy] = useState("CASH");
  const transferImage = useRef("transferImage");
  const [transferSheetTarget, setTransferSheetTarget] = useState({
    file: null,
    previewView: null,
  });

  const [createEntrance] = useMutation(CREATE_ENTRANCE);
  const [updateEntrance] = useMutation(UPDATE_ENTRANCE);
  const [loadDataMajors, { data: dataMajorsApollo }] = useLazyQuery(MAJORS, {
    fetchPolicy: "network-only",
  });
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES, {
    fetchPolicy: "network-only",
  });
  const [loadTribe, { data: dataTribe }] = useLazyQuery(TRIBES);
  const [getEntranceData, { data: entranceData, loading }] =
    useLazyQuery(ENTRANCES);
  const [loadEntrance, { data: studentEntrance }] = useLazyQuery(ENTRANCES);
  const [loadPresignImage, { data: presignData }] =
    useLazyQuery(PRE_SIGNED_URL);
  const [loadScorePresignImage, { data: scorePresignData }] =
    useLazyQuery(PRE_SIGNED_URL);
  const [loadTransferPresignImage, { data: transferPresignData }] =
    useLazyQuery(PRE_SIGNED_URL);

  useEffect(() => {
    loadFaculties({
      variables: {
        where: {
          // group: e?.target?.value,
          isDeleted: false,
          assessmentYear: assessmentYear,
        },
      },
    });
    loadDataMajors({
      variables: {
        where: {
          // faculty: facultyId,
          isDeleted: false,
          // group: group,
          assessmentYear: assessmentYear,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (id) {
      getEntranceData({
        variables: {
          where: { student: id },
        },
      });
      loadTribe();
    }
    loadTribe();
    setLocalstoragePhone(phoneData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studentEntrance) {
      setShowAddConfirmModal(false);
      setTimeout(() => {
        history.push(
          "/cardstudent/" + studentEntrance.entrances.data[0].student.id
        );
        window.location.reload();
      }, 2000);
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentEntrance]);

  useEffect(() => {
    if (entranceData?.entrances?.data[0] && id) {
      const fetch = async () => {
        await loadFaculties({
          variables: {
            where: {
              group: entranceData?.entrances?.data[0]?.group,
              isDeleted: false,
              assessmentYear: assessmentYear,
            },
          },
        });
        setStudent(entranceData?.entrances?.data[0]?.student);
        // setPreviewImageURL(entranceData?.entrances?.data[0]?.student?.image)
        setPreviewImageURL(
          consts.S3_IMAGE + entranceData?.entrances?.data[0]?.student?.image
        );
        setLevel(entranceData?.entrances?.data[0]?.level);
        setGroup(entranceData?.entrances?.data[0]?.group);
        setRegistrationAt(entranceData?.entrances?.data[0]?.registrationAt);
        setTribeName(entranceData?.entrances?.data[0]?.tribe?.name);
        const subjects = JSON.parse(
          JSON.stringify(
            entranceData?.entrances?.data[0]?.student?.highSchoolSubjects ||
              subjs
          )
        );
        for (const sub of subjects) {
          delete sub.__typename;
        }
        setHighSchoolSubject(subjects);
        setPayBy(entranceData?.entrances?.data[0]?.student?.payment || "CASH");

        if (entranceData?.entrances?.data?.length > 0) {
          let _selectRegisters = [];
          for (var i = 0; i < entranceData?.entrances?.data.length; i++) {
            _selectRegisters.push({
              level: entranceData?.entrances?.data[i]?.level,
              group: entranceData?.entrances?.data[i]?.group,
              applyNumber: entranceData?.entrances?.data[i]?.applyNumber,
              faculty: entranceData?.entrances?.data[i]?.faculty?.id,
              facultyName: entranceData?.entrances?.data[i]?.faculty?.name,
              department: entranceData?.entrances?.data[i]?.department?.id,
              departmentName:
                entranceData?.entrances?.data[i]?.department?.name,
              major: entranceData?.entrances?.data[i]?.major?.id,
              majorName: entranceData?.entrances?.data[i]?.major?.name,
              majorCode: entranceData?.entrances?.data[i]?.major?.code,
            });
          }
          setSelectRegisters(
            _selectRegisters.sort((a, b) => {
              return a.applyNumber - b.applyNumber;
            })
          );
        }
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entranceData]);

  useEffect(() => {
    if (facultyData?.faculties) {
      setFaculties(facultyData?.faculties?.data);
    }
  }, [facultyData]);

  useEffect(() => {
    if (dataMajorsApollo?.majors) {
      setMajors(dataMajorsApollo?.majors?.data);
    }
  }, [dataMajorsApollo]);

  // useEffect(() => {
  //   if(isEmpty(facultyId)) return;
  //   loadDataMajors({
  //     variables: {
  //       where: {
  //         faculty: facultyId,
  //         isDeleted: false,
  //         group: group,
  //         assessmentYear: assessmentYear
  //       }
  //     }
  //   })
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [facultyId, group])

  // useEffect(() => {
  //   if(coverImage && !presignData)
  //   getPresignImage(coverImage);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [coverImage, presignData]);

  const _handleClickotp = () => {
    // history.push('/login-from/:studentType')
    history.push("/login-from/registration");
  };

  const _addImage = () => {
    inputImage.current.click();
  };

  const getPresignImage = async (file) => {
    await loadPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: "" + Date.now() + file?.name?.split(".")[0],
      },
    });
  };

  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      setCoverImage(event.target.files[0]);
      getPresignImage(event.target.files[0]);
    }
  };

  const _uploadFile = async (presignData, file) => {
    if (presignData.preSignedUrl) {
      const response = await axios({
        method: "put",
        url: presignData.preSignedUrl.url,
        data: file,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      return response;
    }
  };

  const _selectFaculty = (e) => {
    const getFacultyName = faculties?.filter((f) => f?.id === e?.target?.value);
    if (getFacultyName.length > 0) {
      setFacultyId(e.target.value);
      setFacultyName(getFacultyName[0]?.name);
      setDepartmentId("");
      setMajorCode("");
      setMajorId("");
      setMajorName("");
    }
  };

  const _selectMajor = (e) => {
    const getMajorName = majors?.filter((d) => d?.id === e?.target?.value);
    if (getMajorName.length > 0) {
      setMajorName(getMajorName[0]?.name);
      setMajorCode(getMajorName[0]?.code);
      setDepartmentId(getMajorName[0]?.department?.id);
      setMajorId(e?.target?.value);
    }
  };

  const _selectProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectProvinceIndex(_provinceIndex);
  };
  const _selectBirthProvince = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
  };

  useEffect(() => {
    const _provinceBirthIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.birthAddress?.province,
    });
    setSelectBirthProvinceIndex(_provinceBirthIndex);
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: student?.address?.province,
    });
    setSelectProvinceIndex(_provinceIndex);
  }, [student]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 1; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setGraduatedYear(year);
  }, []);

  const _addScoreImage = () => {
    scoreImage.current.click();
  };
  const _handleChangeScoreImage = (event) => {
    if (event.target.files[0]) {
      setScoreSheetTarget({
        file: event.target.files[0],
        previewView: URL.createObjectURL(event.target.files[0]),
      });
      getScorePresignImage(event.target.files[0]);
    }
  };

  const getScorePresignImage = async (file) => {
    await loadScorePresignImage({
      variables: {
        mimeType: file?.type,
        fileName: "" + Date.now() + file?.name?.split(".")[0],
      },
    });
  };

  const _addTransferImage = () => {
    transferImage.current.click();
  };
  const _handleChangeTransferImage = (event) => {
    if (event.target.files[0]) {
      setTransferSheetTarget({
        file: event.target.files[0],
        previewView: URL.createObjectURL(event.target.files[0]),
      });
      getTransferPresignImage(event.target.files[0]);
    }
  };

  const getTransferPresignImage = async (file) => {
    await loadTransferPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: "" + Date.now() + file?.name?.split(".")[0],
      },
    });
  };

  const onSelectGroup = (e) => {
    if (e?.target?.value) {
      setFacultyId("");
      setFacultyName("");
      setMajorId("");
      setDepartmentId("");
      setMajorName("");
      setMajorCode("");
      setGroup(e?.target?.value);
      // loadFaculties({
      //   variables: {
      //     where: {
      //       group: e?.target?.value,
      //       isDeleted: false,
      //       assessmentYear: assessmentYear
      //     }
      //   }
      // })
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAddSelectRegisters = async () => {
    const checkMajor = selectRegisters.filter(
      (item) => item.major === (majorId !== "" ? majorId : majors.id)
    );

    if (checkMajor.length > 0) {
      swal({
        icon: "warning",
        title: "ມີການເພີ່ມຂໍ້ມູນຊ້ຳຊ້ອນ!",
        buttons: false,
        dangerMode: false,
        className: "swal-wide",
      });
      return;
    }

    if (selectRegisters.length < 3) {
      let _selectRegisters = [...selectRegisters];
      _selectRegisters.push({
        level: level,
        group: group,
        applyNumber: _selectRegisters.length + 1,
        faculty: facultyId !== "" ? facultyId : faculties[0].id,
        facultyName: facultyName !== "" ? facultyName : faculties[0].name,
        department:
          departmentId !== "" ? departmentId : majors[0]?.department?.id,
        departmentName:
          majorName !== "" ? majorName : majors[0]?.department?.name,
        major: majorId !== "" ? majorId : majors[0].id,
        majorName: majorName !== "" ? majorName : majors[0].name,
        majorCode: majorCode !== "" ? majorCode : majors[0].code,
      });
      setFacultyName("");
      setFacultyId("");
      setDepartmentId("");
      setMajors([]);
      setMajorName("");
      setMajorCode("");
      setMajorId("");
      setSelectRegisters(_selectRegisters);
    }
  };

  const onRemoveRegister = (item) => {
    let _newSelectRegisters = [];
    let _selectRegisters = selectRegisters?.filter(
      (register) => register.applyNumber !== item.applyNumber
    );
    for (var i = 0; i < _selectRegisters.length; i++) {
      _newSelectRegisters.push({
        ..._selectRegisters[i],
        applyNumber:
          _selectRegisters[i].applyNumber > item.applyNumber
            ? _selectRegisters[i].applyNumber - 1
            : _selectRegisters[i].applyNumber,
      });
    }
    setSelectRegisters(_newSelectRegisters);
  };

  const getTribeName = (e) => {
    let _tribe = dataTribe?.tribes?.data.filter(
      (item) => item.id === e.target.value
    );
    setTribeName(_tribe[0].name);
  };

  const onCreateStudent = async () => {
    try {
      setIsDisabled(true);
      let _formParam = { ...formParam };
      for (var i = 0; i < _formParam.applies?.length; i++) {
        delete _formParam.applies[i]?.facultyName;
        delete _formParam.applies[i]?.departmentName;
        delete _formParam.applies[i]?.majorName;
        delete _formParam.applies[i]?.majorCode;
      }

      delete _formParam.tribeName;
      let studentData = { ..._formParam };
      for (let key in studentData) {
        if (
          key.startsWith("score") ||
          key.startsWith("fclty") ||
          key.startsWith("mjor")
        ) {
          delete studentData[key];
        }
      }
      delete studentData.applies;
      delete studentData.comingFrom;
      delete studentData.tribe;
      let dataInput = {
        phoneMobile: _formParam.phoneMobile,
        student: studentData,
        applies: _formParam.applies,
        comingFrom: _formParam.comingFrom,
        tribe: _formParam.tribe,
        registrationAt: registrationAt,
      };

      let newData = {
        phoneMobile: _formParam.phoneMobile,
        student: studentData,
        applies: _formParam.applies,
        comingFrom: _formParam.comingFrom,
        tribe: _formParam.tribe,
        registrationAt: registrationAt,
        status: "REQUESTING",
      };

      if (id) {
        const _updateEntrance = await updateEntrance({
          variables: {
            data: newData,
            where: { id },
          },
        });
        if (_updateEntrance?.data?.updateEntrance?.id) {
          loadEntrance({
            variables: {
              where: { id: _updateEntrance?.data?.updateEntrance?.id },
            },
          });
          setIsDisabled(false);
        }
      } else {
        const _createEntrance = await createEntrance({
          variables: {
            data: dataInput,
          },
        });
        if (_createEntrance?.data?.createEntrance?.id) {
          loadEntrance({
            variables: {
              where: { id: _createEntrance?.data?.createEntrance?.id },
            },
          });
          setIsDisabled(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const convertFieldName = (property) => {
    let res = property;
    switch (property) {
      case "firstNameL":
        res = "ຊື່";
        break;
      case "lastNameL":
        res = "ນາມສະກຸນ";
        break;
      // case 'firstNameE':
      //   res = 'ຊື່(ພາສາອັງກິດ)'
      //   break
      // case 'lastNameE':
      //   res = 'ນາມສະກຸນ(ພາສາອັງກິດ)'
      //   break
      case "birthday":
        res = "ວັນເດືອນປີເກີດ";
        break;
      case "nationality":
        res = "ສັນຊາດ";
        break;
      case "tribe":
        res = "ຊົນເຜົ່າ";
        break;
      case "religion":
        res = "ສາສະຫນາ";
        break;
      // case 'province':
      //   res = 'ແຂວງ'
      //   break
      // case 'district':
      //   res = 'ເມືອງ'
      //   break
      // case 'village':
      //   res = 'ບ້ານ'
      //   break
      // case 'birthProvince':
      //   res = 'ແຂວງເກີດ'
      //   break
      // case 'birthDistrict':
      //   res = 'ເມືອງເກີດ'
      //   break
      // case 'birthVillage':
      //   res = 'ບ້ານເກີດ'
      //   break
      case "outPlanGraduateFromProvince":
        res = "ແຂວງຮຽນຈົບ";
        break;
      case "outPlanGraduateFromDistrict":
        res = "ເມືອງຮຽນຈົບ";
        break;
      case "outPlanGraduateFromSchool":
        res = "ໂຮງຮຽນຈົບ";
        break;
      case "outPlanSemesterYear":
        res = "ສົກສຶກສາທີ່ຈົບ";
        break;
      case "highSchoolSummaryScore":
        res = "ຄະແນນລວມ";
        break;
      case "highSchoolAverageScore":
        res = "ຄະແນນສະເລ່ຍ";
        break;
      case "registerBillNo":
        res = "ເລກທີ່ໃບບິນ";
        break;
      default:
    }
    return res;
  };

  if (loading) return <Loading />;
  return (
    <div>
      {
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstNameL: student?.firstNameL ?? "",
            lastNameL: student?.lastNameL ?? "",
            firstNameE: student?.firstNameE ?? "",
            lastNameE: student?.lastNameE ?? "",
            birthday: student?.birthday ?? "",
            gender: student?.gender ?? "MALE",
            maritalStatus: student?.maritalStatus ?? "SINGLE",
            nationality: student?.nationality ?? "",
            tribe: entranceData?.entrances?.data[0]?.tribe?.id ?? "",
            religion: student?.religion ?? "",
            phoneHome: student?.phoneHome ?? "",
            phoneMobile: student?.phoneMobile
              ? student?.phoneMobile
              : localstoragePhone
              ? JSON.parse(localstoragePhone)
              : "",
            email: student?.email ?? "",
            province: student?.address?.province ?? "",
            district: student?.address?.district ?? "",
            village: student?.address?.village ?? "",
            birthProvince: student?.birthAddress?.province ?? "",
            birthDistrict: student?.birthAddress?.district ?? "",
            birthVillage: student?.birthAddress?.village ?? "",
            outPlanGraduateFromProvince:
              entranceData?.entrances?.data[0]?.comingFrom?.graduatedProvince ??
              "",
            outPlanGraduateFromDistrict:
              entranceData?.entrances?.data[0]?.comingFrom?.graduatedDistrict ??
              "",
            outPlanGraduateFromSchool:
              entranceData?.entrances?.data[0]?.comingFrom?.graduatedSchool ??
              "",
            outPlanSemesterYear:
              entranceData?.entrances?.data[0]?.comingFrom
                ?.graduatedAccessMentYear ?? "",
            contactName: student?.emergencyContact?.contactName ?? "",
            emergencyPhone: student?.emergencyContact?.emergencyPhone ?? "",
            isLiveTogether: student?.emergencyContact?.isLiveTogether ?? false,
            explainNotToStayTogether:
              student?.emergencyContact?.explainNotToStayTogether ?? "",
            highSchoolSummaryScore: student?.highSchoolSummaryScore ?? "",
            highSchoolAverageScore: student?.highSchoolAverageScore ?? "",
            registerFee: 40000,
            registerBillNo: student?.registerBillNo ?? "",
          }}
          validate={(values) => {
            const _validate = () => {
              let _errorsValidate = [];
              for (const property in values) {
                const noValidateList = [
                  "birthProvince",
                  "birthDistrict",
                  "birthVillage",
                  "firstNameE",
                  "lastNameE",
                ];
                if (noValidateList.includes(property)) continue;
                if (
                  property.startsWith("score") ||
                  property.startsWith("fclty") ||
                  property.startsWith("mjor")
                ) {
                  continue;
                }
                if (property == "registerBillNo" && payBy != "CASH") continue;
                if (!values[property]) {
                  _errorsValidate.push({
                    message: "ກະລຸນາປ້ອນ " + convertFieldName(property),
                  });
                }
              }
              setErrorValidate(_errorsValidate);
            };

            // if (!values.firstNameE) {
            //   delete values.firstNameE
            //   // _validate()
            // }
            // if (!values.lastNameE) {
            //   delete values.lastNameE
            //   // _validate()
            // }
            if (!values.province) {
              delete values.province;
              // _validate()
            }
            if (!values.district) {
              delete values.district;
              // _validate()
            }
            if (!values.village) {
              delete values.village;
              // _validate()
            }
            if (!values.email) {
              delete values.email;
              _validate();
            }
            if (!values.phoneHome) {
              delete values.phoneHome;
              _validate();
            }
            if (!values.contactName) {
              delete values.contactName;
              _validate();
            }
            if (!values.emergencyPhone) {
              delete values.emergencyPhone;
              _validate();
            }
            if (!values.explainNotToStayTogether) {
              delete values.explainNotToStayTogether;
              _validate();
            }
            if (!values.isLiveTogether) {
              delete values.isLiveTogether;
              _validate();
            }
            _validate();
          }}
          onSubmit={async (values) => {
            let compareRegister = true;
            for (const reg of selectRegisters) {
              compareRegister =
                compareRegister && reg.group && reg.major && reg.faculty;
            }
            let compareScore = true;
            for (const subj of highSchoolSubjects) {
              compareScore = compareScore && subj.subject && subj.score;
            }
            if (
              selectRegisters.length < 1 ||
              !compareRegister ||
              !compareScore /* || registrationAt === '' || !previewImageURL */ ||
              !(scoreSheetTarget.file || student?.highSchoolScoreImage) ||
              !(payBy == "TRANSFER"
                ? transferSheetTarget.file || student?.registerFeeImage
                : true) ||
              errorValidate.length > 0
            ) {
              swal({
                icon: "warning",
                title: "ມີຂໍ້ຜິດພາດ!",
                content: (
                  <div>
                    {selectRegisters.length < 1 && (
                      <p>ກະລຸນາເລືອກສາຂາວິຊາທີ່ຈະຄັດເລືອກ</p>
                    )}
                    {/* {registrationAt === '' && (
                      <p>ກະລຸນາເລືອກສະຖານທີ່ຄັດເລືອກ</p>
                    )}
                    {!previewImageURL && (
                      <p>ກະລຸນາອັບໂຫຼດຮູບ</p>
                    )} */}
                    {!scoreSheetTarget.file && <p>ກະລຸນາອັບໂຫຼດຮູບໃບຄະແນນ</p>}
                    {!compareRegister && (
                      <p>ກະລຸນາເລືອກສາຍສະໝັກຄັດເລືອກໃຫ້ຄົບຖ້ວນ</p>
                    )}
                    {errorValidate.map((err) => (
                      <p>{err.message}</p>
                    ))}
                    {!compareScore && (
                      <p>ກະລຸນາປ້ອນຄະແນນຈົບ ມ.ປາຍ ໃຫ້ຄົບຖ້ວນ</p>
                    )}
                    {!(payBy == "TRANSFER"
                      ? transferSheetTarget.file || student?.registerFeeImage
                      : true) && <p>ກະລຸນາອັບໂຫຼດຮູບຫຼັກຖານໂອນເງິນ</p>}
                  </div>
                ),
                buttons: false,
                dangerMode: false,
              });
              return;
            }
            setIsLoading(true);
            let _selectRegisters = [...selectRegisters];

            // let fileName = null;
            // if (coverImage) {
            //   const res = await _uploadFile(presignData, coverImage);
            //   fileName = res?.request?.responseURL?.split("?")[0].split("/")[4];
            //   values = {
            //     ...values, image: fileName,
            //   };
            // }

            let scoreFileName = null;
            if (scoreSheetTarget?.file && scorePresignData) {
              const res = await _uploadFile(
                scorePresignData,
                scoreSheetTarget?.file
              );
              scoreFileName = res?.request?.responseURL
                ?.split("?")[0]
                .split("/")[4];
              values = {
                ...values,
                highSchoolScoreImage: scoreFileName,
              };
            }

            let transferFileName = null;
            if (transferSheetTarget?.file && transferPresignData) {
              const res = await _uploadFile(
                transferPresignData,
                transferSheetTarget?.file
              );
              transferFileName = res?.request?.responseURL
                ?.split("?")[0]
                .split("/")[4];
              values = {
                ...values,
                registerFeeImage: transferFileName,
              };
            }

            values.highSchoolSummaryScore = !isNaN(
              values.highSchoolSummaryScore
            )
              ? Number(values.highSchoolSummaryScore)
              : 0;
            values.highSchoolAverageScore = !isNaN(
              values.highSchoolAverageScore
            )
              ? Number(values.highSchoolAverageScore)
              : 0;
            values.registerFee = !isNaN(values.registerFee)
              ? Number(values.registerFee)
              : 0;

            let newData = {
              ...values,
              highSchoolSubjects: highSchoolSubjects,
              payment: payBy,
              address: {
                province: values.province,
                district: values.district,
                village: values.village,
              },
              birthAddress: {
                province: values.birthProvince,
                district: values.birthDistrict,
                village: values.birthVillage,
              },
              comingFrom: {
                graduatedProvince: values.outPlanGraduateFromProvince,
                graduatedDistrict: values.outPlanGraduateFromDistrict,
                graduatedSchool: values.outPlanGraduateFromSchool,
                graduatedAccessMentYear: values.outPlanSemesterYear,
              },
              emergencyContact: {
                contactName: values.contactName,
                isLiveTogether: values.isLiveTogether ?? false,
                emergencyPhone: values.emergencyPhone,
                explainNotToStayTogether: values.explainNotToStayTogether ?? "",
              },
              applies: _selectRegisters,
              tribeName: tribeName,
              tribe: values.tribe,
            };

            delete newData.birthProvince;
            delete newData.birthDistrict;
            delete newData.birthVillage;
            delete newData.outPlanSemesterYear;
            delete newData.outPlanGraduateFromProvince;
            delete newData.outPlanGraduateFromDistrict;
            delete newData.outPlanGraduateFromSchool;
            delete newData.province;
            delete newData.district;
            delete newData.village;
            delete newData.contactName;
            delete newData.emergencyPhone;
            delete newData.isLiveTogether;
            delete newData.explainNotToStayTogether;
            setFormParam(newData);
            setIsLoading(false);
            _handleShowAddConfirmModalShow();
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
            <div className="card card_registrationStudents">
              <div className="card-body card_registerstrationStudents_body">
                <h6 className="mt-3 textForm">
                  ຟອມລົງທະບຽນຄັດເລືອກ
                  <span className="float-right ">
                    <a
                      href="https://cu-bucket-files.s3.ap-southeast-1.amazonaws.com/files/entrance2023-2024.pdf"
                      target="bank"
                    >
                      <u>ຄູ່ມືແນະນຳ</u>
                    </a>
                  </span>
                </h6>
                <div className="card bg-register">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</div>
                <div className="row margin-resgintrationStudent">
                  {/* <div className='col-12'>
                    <label>
                      <span className='text-bold'>ຮູບນັກສຶກສາ</span> (ໝາຍເຫດ: ຕ້ອງແມ່ນຮູບ 3x4 ທີ່ຕິດໃບປະກາດ ມ7)<span className='text-danger'>*</span>
                    </label>
                    <div className='browserImage' style={{borderColor: previewImageURL ? "#ccc" : 'red'}} onClick={_addImage}>
                      {previewImageURL ? 
                        <img alt="" src={previewImageURL} className="image-preview" />
                        : 
                        <div className='text-center row'>
                          <div className='col-12 mb-2'><FontAwesomeIcon icon={faUpload} size="3x" color='#AAA' /></div>
                          <span className='upload-image-title'>ກົດອັບໂຫຼດຮູບ</span>
                          <span className='upload-imageSize'>3 x 4</span>
                        </div>
                      }
                      <input
                        type="file"
                        id="inputImage"
                        multiple
                        name="image"
                        ref={inputImage}
                        style={{ display: "none" }}
                        onChange={_handleChangeImage}
                        accept="image/*"
                      />
                    </div>
                  </div> */}
                  <div className="col-xs-12 col-md-2 mt-3">
                    <label className="text-bold">
                      ເລືອກເພດ <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-xs-6 col-md-2 mt-3">
                    <Form.Group>
                      <Form.Check
                        inline
                        label="ທ້າວ"
                        type="radio"
                        value="MALE"
                        id="gender-radio"
                        name="gender"
                        onChange={handleChange}
                        checked={values.gender === "MALE" ? true : false}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-6 col-md-2 mt-3">
                    <Form.Group>
                      <Form.Check
                        inline
                        label="ນາງ"
                        type="radio"
                        value="FEMALE"
                        id="gender-radio"
                        name="gender"
                        onChange={handleChange}
                        checked={values.gender === "FEMALE" ? true : false}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-6 col-md-2 mt-3">
                    <Form.Group>
                      <Form.Check
                        inline
                        label="ພຣະ"
                        type="radio"
                        value="MONK"
                        id="gender-radio"
                        name="gender"
                        onChange={handleChange}
                        checked={values.gender === "MONK" ? true : false}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-6 col-md-2 mt-3">
                    <Form.Group>
                      <Form.Check
                        inline
                        label="ສໍາມະເນນ"
                        type="radio"
                        value="NOVICE"
                        id="gender-radio"
                        name="gender"
                        onChange={handleChange}
                        checked={values.gender === "NOVICE" ? true : false}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຊື່ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={values.firstNameL}
                      placeholder="ກະ​ລຸ​ນາ ປ້ອນ​ຊື່​ແທ້​ຂອງ​ທ່ານ"
                      name="firstNameL"
                      onChange={handleChange}
                      isInvalid={errors.firstNameL}
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ນາມສະກຸນ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={values.lastNameL}
                      placeholder="ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ"
                      name="lastNameL"
                      onChange={handleChange}
                      isInvalid={errors.lastNameL}
                    />
                  </div>
                  {/* <div className='col-md-6 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ຊື່ (ພາສາອັງກິດ)
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.firstNameE}
                      placeholder='ກະ​ລຸ​ນາ ປ້ອນຊື່​ແທ້​ເປັນ​ພາ​ສາ​ອັງ​ກິດ'
                      name='firstNameE'
                      onChange={handleChange}
                    // isInvalid={errors.firstNameE}
                    />
                  </div>
                  <div className='col-md-6 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ນາມສະກຸນ (ພາສາອັງກິດ)
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.lastNameE}
                      placeholder='ກະ​ລຸ​ນາ ປ້ອນນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ'
                      name='lastNameE'
                      onChange={handleChange}
                      isInvalid={errors.lastNameE}
                    />
                  </div> */}
                  <div className="col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ວັນ​ເດືອນ​ປີ​ເກີດ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      value={values.birthday}
                      placeholder="12/02/2000"
                      name="birthday"
                      onChange={handleChange}
                      isInvalid={errors.birthday}
                    />
                  </div>
                  <div className="col-md-6 mt-3"></div>
                  {/* <div className='row'>
                    <div className='col-xs-12 col-md-4'>
                      <Form.Label fontSize='14' className='text-bold'>
                        ແຂວງເກີດ <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as='select'
                        name='birthProvince'
                        onChange={e => {
                          _selectBirthProvince(e)
                          handleChange(e)
                        }}
                        isInvalid={errors.birthProvince}
                        value={values.birthProvince}
                      >
                        <option value=''>---ເລືອກແຂວງ---</option>
                        {LAO_ADDRESS.map((province, index) => (
                          <option
                            key={'province' + index}
                            value={province?.province_name}
                          >
                            {province?.province_name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                      <Form.Label fontSize='14' className='text-bold'>
                        ເມືອງເກີດ <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        as='select'
                        value={values.birthDistrict}
                        name='birthDistrict'
                        isInvalid={errors.birthDistrict}
                        onChange={handleChange}
                      >
                        <option value=''>---ເລືອກເມືອງ---</option>
                        {selectBirthProvinceIndex > -1 &&
                          LAO_ADDRESS[selectBirthProvinceIndex].district_list.map(
                            (district, index) => (
                              <option
                                key={'birth-district' + index}
                                value={district.district}
                              >
                                {district.district}
                              </option>
                            )
                          )}
                      </Form.Control>
                    </div>
                    <div className='col-xs-12 col-md-4'>
                      <Form.Label fontSize='14' className='text-bold'>
                        ບ້ານເກີດ <span className='text-danger'>*</span>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        name='birthVillage'
                        value={values.birthVillage}
                        isInvalid={errors.birthVillage}
                        placeholder='ກະລຸນາປ້ອນບ້ານ'
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}
                  {/* <div className='col-xs-12 col-md-2'>
                    <label className='text-bold'>
                      ສະຖານະ <span className='text-danger'>*</span>
                    </label>
                  </div>
                  <div className='col-xs-12 col-md-2'>
                    {' '}
                    <Form.Group>
                      <Form.Check
                        inline
                        label='ໂສດ'
                        type='radio'
                        value='SINGLE'
                        id='maritalStatus-radio'
                        name='maritalStatus'
                        onChange={handleChange}
                        checked={
                          values.maritalStatus === 'SINGLE' ? true : false
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className='col-xs-12 col-md-6'>
                    <Form.Group>
                      <Form.Check
                        inline
                        label='ແຕ່ງງານແລ້ວ'
                        type='radio'
                        id='maritalStatus-radio'
                        value='MARRIAGE'
                        name='maritalStatus'
                        onChange={handleChange}
                        checked={
                          values.maritalStatus === 'MARRIAGE' ? true : false
                        }
                      />
                    </Form.Group>
                  </div> */}
                  <div className="col-md-4 mt-3">
                    <Form.Label fontSize="14" className="text-bold">
                      ສັນຊາດ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.nationality}
                      name="nationality"
                      onChange={handleChange}
                      isInvalid={errors.nationality}
                    >
                      <option value="">ເລືອກ</option>
                      <option value="ລາວ">ລາວ</option>
                      <option value="ໄທ">ໄທ</option>
                      <option value="ຈີນ">ຈີນ</option>
                      <option value="ຫວຽດ">ຫວຽດ</option>
                    </Form.Control>
                  </div>
                  <div className="col-md-4 mt-3">
                    <Form.Label fontSize="14" className="text-bold">
                      ຊົນເຜົ່າ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.tribe}
                      name="tribe"
                      onChange={(e) => {
                        getTribeName(e);
                        handleChange(e);
                      }}
                      isInvalid={errors.tribe}
                    >
                      <option value="">ເລືອກ</option>
                      {dataTribe?.tribes?.data?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                  <div className="col-md-4 mt-3">
                    <Form.Label fontSize="14" className="text-bold">
                      ສາສະໜາ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={values.religion}
                      name="religion"
                      onChange={handleChange}
                      isInvalid={errors.religion}
                    >
                      <option value="">ເລືອກ</option>
                      <option value="​ສາ​ສ​ະ​ໜາ​ພຸດ">​ສາ​ສ​ະ​ໜາ​ພຸດ</option>
                      <option value="ສາ​ສະ​ໜາຜີ">ສາ​ສະ​ໜາຜີ</option>
                      <option value="ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ">
                        ສາ​ສະ​ໜາຄຣິສະ​ຕຽນ
                      </option>
                      <option value="ສາ​ສະ​ໜາອິດສະລາມ">ສາ​ສະ​ໜາອິດສະລາມ</option>
                    </Form.Control>
                  </div>
                  {/* <div className='col-md-4 mt-3'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ເບີໂທເຮືອນ
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.phoneHome}
                      placeholder='ກະລຸນາປ້ອນເບີໂທເຮືອນ'
                      name='phoneHome'
                      isInvalid={errors.phoneHome}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='col-md-4 mt-3'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ເບີໂທລະສັບມືຖື
                    </Form.Label>
                    <Form.Control
                      type='text'
                      disabled
                      value={values.phoneMobile}
                      placeholder={'ກະລຸນາປ້ອນເບີໂທລະສັບມືຖື'}
                      name='phoneMobile'
                      isInvalid={errors.phoneMobile}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='col-md-4 mt-3'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ອີເມວ
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.email}
                      placeholder='ກະລຸນາປ້ອນອີເມວ'
                      name='email'
                      isInvalid={errors.email}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>
                {/* <div className='card bg-register'>ທີ່ຢູ່ປະຈຸບັນ</div>
                <div className='row'>
                  <div className='col-xs-12 col-md-4 mt-2'>
                    {' '}
                    <Form.Label fontSize='14' className='text-bold'>
                      ແຂວງ
                    </Form.Label>
                    <Form.Control
                      as='select'
                      name='province'
                      onChange={e => {
                        _selectProvince(e)
                        handleChange(e)
                      }}
                      value={values.province}
                      isInvalid={errors.province}
                      id='disabledSelect'
                      placeholder='ເລືອກແຂວງ'
                    >
                      <option value=''>---ເລືອກແຂວງ---</option>
                      {LAO_ADDRESS.map((province, index) => (
                        <option
                          key={'province' + index}
                          value={province?.province_name}
                        >
                          {province?.province_name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div className='col-xs-12 col-md-4 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ເມືອງ
                    </Form.Label>
                    <Form.Control
                      as='select'
                      // value={selectDistrict}
                      isInvalid={errors.district}
                      name='district'
                      onChange={e => {
                        handleChange(e)
                      }}
                      value={values.district}
                    >
                      <option value=''>---ເລືອກເມືອງ---</option>
                      {selectProvinceIndex > -1 &&
                        LAO_ADDRESS[selectProvinceIndex].district_list.map(
                          (district, index) => (
                            <option key={'district' + index}>
                              {district.district}
                            </option>
                          )
                        )}
                    </Form.Control>
                  </div>
                  <div className='col-xs-12 col-md-4 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ບ້ານ
                    </Form.Label>
                    <Form.Control
                      type='text'
                      name='village'
                      isInvalid={errors.village}
                      placeholder='ກະລຸນາປ້ອນບ້ານ'
                      onChange={handleChange}
                      value={values.village}
                    />
                  </div>
                </div> */}

                <div className="card bg-register">ຂໍ້ມູນໂຮງຮຽນທີ່ຈົບ</div>
                <div className="row">
                  <div className="col-xs-12 col-md-3 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຈົບຈາກແຂວງ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="outPlanGraduateFromProvince"
                      isInvalid={errors.outPlanGraduateFromProvince}
                      onChange={handleChange}
                      value={values.outPlanGraduateFromProvince}
                    >
                      <option value="">---ເລືອກແຂວງ---</option>
                      {LAO_ADDRESS.map((province, index) => (
                        <option
                          key={"province" + index}
                          value={province?.province_name}
                        >
                          {province?.province_name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div className="col-xs-12 col-md-3 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຈົບຈາກເມືອງ <span className="text-danger">*</span>
                    </Form.Label>
                    {/* <Form.Control
                      type='text'
                      placeholder='ກະລຸນາປ້ອນ'
                      value={values.outPlanGraduateFromDistrict}
                      name='outPlanGraduateFromDistrict'
                      isInvalid={errors.outPlanGraduateFromDistrict}
                      onChange={handleChange}
                    /> */}
                    <Form.Control
                      as="select"
                      value={values.outPlanGraduateFromDistrict}
                      name="outPlanGraduateFromDistrict"
                      isInvalid={errors.outPlanGraduateFromDistrict}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">---ເລືອກເມືອງ---</option>
                      {LAO_ADDRESS?.find(
                        (itm) =>
                          itm.province_name ==
                          values.outPlanGraduateFromProvince
                      )?.district_list?.map((district, index) => (
                        <option key={"district" + index}>
                          {district.district}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div className="col-xs-12 col-md-3 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຈົບຈາກໂຮງຮຽນ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="ກະລຸນາປ້ອນ"
                      value={values.outPlanGraduateFromSchool}
                      name="outPlanGraduateFromSchool"
                      isInvalid={errors.outPlanGraduateFromSchool}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-12 col-md-3 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ສົກສືກສາ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="outPlanSemesterYear"
                      isInvalid={errors.outPlanSemesterYear}
                      onChange={handleChange}
                      value={values.outPlanSemesterYear}
                    >
                      <option>---ເລືອກສົກສືກສາ---</option>
                      {graduatedYear.map((x, index) => {
                        return (
                          <option value={x} key={index}>
                            {x}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </div>
                </div>

                <div className="card bg-register">ຂໍ້ມູນຄະແນນຈົບ ມ.ປາຍ</div>
                <div className="row">
                  <div className="col-xs-12 col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຄະແນນລວມ <span className="text-danger">*</span>
                    </Form.Label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          ຄະແນນສຸງສຸດແມ່ນ {highSchoolSubjects.length * 10}
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        max={highSchoolSubjects.length * 10}
                        min="0"
                        maxLength="2"
                        type="number"
                        step={0.1}
                        placeholder="ກະລຸນາປ້ອນຄະແນນລວມ"
                        value={values.highSchoolSummaryScore}
                        name="highSchoolSummaryScore"
                        isInvalid={errors.highSchoolSummaryScore}
                        onChange={(e) => {
                          try {
                            if (
                              Number(e.target.value) >
                              highSchoolSubjects.length * 10
                            ) {
                              return e.preventDefault();
                            }
                          } catch (error) {
                            return e.preventDefault();
                          }
                          handleChange(e);
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="col-xs-12 col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຄະແນນສະເລ່ຍ <span className="text-danger">*</span>
                    </Form.Label>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          ຄະແນນສຸງສຸດແມ່ນ 10.00
                        </Tooltip>
                      }
                    >
                      <Form.Control
                        max="10"
                        min="0"
                        maxLength="2"
                        type="number"
                        step={0.1}
                        placeholder="ກະລຸນາປ້ອນຄະແນນສະເລ່ຍ"
                        value={values.highSchoolAverageScore}
                        name="highSchoolAverageScore"
                        isInvalid={errors.highSchoolAverageScore}
                        onChange={(e) => {
                          try {
                            if (Number(e.target.value) > 10) {
                              return e.preventDefault();
                            }
                          } catch (error) {
                            return e.preventDefault();
                          }
                          handleChange(e);
                        }}
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="col-xs-12 mt-4">
                    <Form.Label fontSize="14" className="text-bold">
                      ຄະແນນແຕ່ລະວິຊາສອບເສັງ{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <table
                      className="table  table-bordered"
                      style={{ marginTop: "-8px" }}
                    >
                      <thead>
                        <tr>
                          <th style={{ width: "20px" }}>ລຳດັບ</th>
                          <th>
                            ວິຊາ <span className="text-danger">*</span>
                          </th>
                          <th>
                            ຄະແນນ <span className="text-danger">*</span>
                          </th>
                          <th className="text-center" style={{ width: "20px" }}>
                            ຈັດການ
                          </th>
                        </tr>
                      </thead>
                      {highSchoolSubjects?.map((item, index) => (
                        <tbody>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <Form.Control
                                type="text"
                                name={"subject" + index}
                                disabled={item.isMain}
                                value={highSchoolSubjects[index].subject ?? ""}
                                // defaultValue={_type?.score ?? null}
                                isInvalid={
                                  !highSchoolSubjects[index].subject
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  highSchoolSubjects[index].subject =
                                    e.target.value;
                                  setHighSchoolSubject(highSchoolSubjects);
                                  handleChange(e);
                                }}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    ຄະແນນສຸງສຸດແມ່ນ 10
                                  </Tooltip>
                                }
                              >
                                <Form.Control
                                  max="10"
                                  min="0"
                                  maxLength="2"
                                  type="number"
                                  step={0.1}
                                  name={"score" + index}
                                  value={highSchoolSubjects[index].score ?? ""}
                                  // defaultValue={_type?.score ?? null}
                                  isInvalid={
                                    !(
                                      (highSchoolSubjects[index].score &&
                                        isNaN(
                                          highSchoolSubjects[index].score
                                        )) ||
                                      (highSchoolSubjects[index].score &&
                                        highSchoolSubjects[index].score >= 0 &&
                                        highSchoolSubjects[index].score <= 10)
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    try {
                                      if (Number(e.target.value) > 10) {
                                        return e.preventDefault();
                                      }
                                    } catch (error) {
                                      return e.preventDefault();
                                    }
                                    highSchoolSubjects[index].score = e.target
                                      .value
                                      ? Number(e.target.value)
                                      : e.target.value;
                                    setHighSchoolSubject(highSchoolSubjects);
                                    handleChange(e);
                                  }}
                                />
                              </OverlayTrigger>
                            </td>
                            <td className="text-center">
                              {!item?.isMain ? (
                                <label
                                  onClick={() => {
                                    highSchoolSubjects.splice(index, 1);
                                    setHighSchoolSubject([
                                      ...highSchoolSubjects,
                                    ]);
                                  }}
                                  style={{ color: "red", cursor: "pointer" }}
                                >
                                  ລຶບ
                                </label>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div className="text-end" style={{ marginTop: "-8px" }}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ເພີ່ມວິຊາຮຽນອື່ນ ນອກຈາກ 5 ວິຊາຫຼັກຂ້າງເທີງ
                          </Tooltip>
                        }
                      >
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            const items = [
                              ...highSchoolSubjects,
                              {
                                subject: "",
                                score: "",
                                isMain: false,
                              },
                            ];
                            setHighSchoolSubject(items);
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="col-xs-12 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ຮູບໃບຄະແນນສອບເສັງ ມ.ປາຍ
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                      }}
                    >
                      <input
                        type="file"
                        id="scoreImage"
                        accept="image/*"
                        name="image"
                        ref={scoreImage}
                        style={{ display: "none" }}
                        onChange={_handleChangeScoreImage}
                      />
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            ອັບໂຫຼດຮູບໃບຄະແນນ
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="outline-secondary"
                          onClick={_addScoreImage}
                          style={{ width: "100%" }}
                        >
                          <i className="fa fa-download"></i> ອັບໂຫຼດໃບຄະແນນ
                          (ຮູບ)
                        </Button>
                      </OverlayTrigger>
                      <img
                        alt=""
                        src={
                          scoreSheetTarget?.previewView
                            ? scoreSheetTarget?.previewView
                            : student?.highSchoolScoreImage
                            ? consts.S3_IMAGE + student?.highSchoolScoreImage
                            : "/assets/img-background.jpg"
                        }
                        style={{
                          width: "100%",
                        }}
                        className="mt-2"
                      />
                    </div>
                  </div>
                </div>

                <div className="card bg-register">
                  <p className="m-0">
                    ເລືອກສາຍສະໝັກຄັດເລືອກເຂົ້າຮຽນ ມຈ
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          ແນະນຳ: ໃນການເລືອກສາຂາວິຊານັ້ນ
                          ຜູ້ສະໝັກຕ້ອງເລືອກລະດັບປະລິນຍາຕີທັງ 3 ສາຂາ:
                          ສາຂາວິຊາຕົ້ນຕໍ, ສາຂາວິຊາສຳຮອງ 1, ສາຂາວິຊາສຳຮອງ 2.
                          ສາຂາວິຊາທີ່ເລືອກ ທຳອິດ ຈະເປັນສາຂາວິຊາຕົ້ນຕໍ,
                          ສ່ວນສາຂາວິຊາທີ່ເລືອກເປັນລຳດັບທີ 2, 3 ຈະເປັນສາຂາສຳຮອງ.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </OverlayTrigger>
                  </p>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ລະດັບ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="level"
                      onChange={(e) => setLevel(e?.target?.value)}
                      isInvalid={level === ""}
                      value={level}
                    >
                      <option value="" disabled>
                        ເລືອກລະດັບ
                      </option>
                      {/* <option value="ອະນຸປະລິນຍາ">ອະນຸປະລິນຍາ</option> */}
                      <option value="ປະລິນຍາຕີ">ປະລິນຍາຕີ</option>
                      {/* <option value="ປະລິນຍາຕີຕໍ່ເນື່ອງ">ປະລິນຍາຕີຕໍ່ເນື່ອງ</option> */}
                    </Form.Control>
                  </div>
                  <div className="col-xs-12 col-md-6 mt-2">
                    <Form.Label fontSize="14" className="text-bold">
                      ກຸ່ມຫຼັກສູດ <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="group"
                      value={group}
                      isInvalid={group === ""}
                      onChange={(e) => {
                        for (let reg of selectRegisters) {
                          reg.group = e.target.value;
                          reg.faculty = "";
                          reg.major = "";
                          reg.department = "";
                        }
                        onSelectGroup(e);
                      }}
                    >
                      <option value="" disabled>
                        {" "}
                        ເລືອກກຸ່ມຫຼັກສູດ{" "}
                      </option>
                      <option value="A">ກຸ່ມ A</option>
                      <option value="B">ກຸ່ມ B</option>
                      {/* {selectRegisters?.length === 0 && (
                        <option value='A'>ກຸ່ມ A</option>
                      )}
                      {selectRegisters?.length === 0 && (
                        <option value='B'>ກຸ່ມ B</option>
                      )}
                      {selectRegisters?.length > 0 && (
                        <option value={group}>{group}</option>
                      )} */}
                    </Form.Control>
                  </div>
                  {/* <div className='col-xs-12 col-md-2 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ຄະ​ນ​ະວິຊາ <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='select'
                      name='faculty'
                      isInvalid={
                        facultyId === '' && selectRegisters?.length === 0
                      }
                      value={facultyId}
                      onChange={e => _selectFaculty(e)}
                    >
                      <option disabled value=''>
                        ເລືອກຄະນະວິຊາ
                      </option>
                      {faculties?.map((x, index) => (
                        <option key={index} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div className='col-xs-12 col-md-2 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ສາ​ຂາ​ວິ​ຊາ
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='select'
                      name='major'
                      isInvalid={
                        majorId === '' && selectRegisters?.length === 0
                      }
                      value={majorId}
                      onChange={e => _selectMajor(e)}
                    >
                      <option disabled value=''>
                        ເລືອກສາ​ຂາ​ວິ​ຊາ
                      </option>
                      {majors?.map((x, index) => (
                        <option key={index} value={x.id}>
                          {x.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                  <div className='col-xs-12 col-md-2 mt-2'>
                    <Button
                      type='add new'
                      disabled={selectRegisters.length >= 3 || !level || !facultyId || !majorId}
                      style={{
                        backgroundColor: selectRegisters.length < 3 ? '#057CAE' : 'grey',
                        color: '#fff',
                        marginTop: 30,
                        height: 40,
                        width: '100%'
                      }}
                      onClick={() => onAddSelectRegisters()}
                    >
                      + ເພີ່ມ
                    </Button>
                  </div> */}

                  <div className="col-xs-12">
                    <div className="mt-2">
                      <strong>
                        ສາ​ຂາ​ວິ​ຊາ
                        <span className="text-danger">*</span>
                      </strong>
                    </div>
                    {selectRegisters?.length > 0 &&
                      selectRegisters?.map((item, index) => (
                        <div className="row mb-3">
                          <div className="col-sm-2">
                            <div
                              style={{
                                border: "1px #CED4DA solid",
                                borderRadius: "3px",
                                paddingTop: 6,
                                paddingBottom: 6,
                                paddingLeft: 4,
                                paddingRight: 4,
                                background: "#E9ECEF",
                                // fontSize: 20
                              }}
                            >
                              <strong>ສາຂາ {index + 1}</strong>
                            </div>
                          </div>
                          <div className="col-sm-5">
                            <Form.Control
                              as="select"
                              name={"fclty" + index}
                              isInvalid={
                                selectRegisters[index].faculty ? false : true
                              }
                              value={selectRegisters[index].faculty}
                              onChange={(e) => {
                                selectRegisters[index].faculty = e.target.value;
                                const found = faculties.find(
                                  (im) => im.id == e.target.value
                                );
                                selectRegisters[index].facultyName =
                                  found?.name;

                                selectRegisters[index].department = "";
                                selectRegisters[index].departmentName = "";
                                selectRegisters[index].majorName = "";
                                selectRegisters[index].majorCode = "";
                                selectRegisters[index].major = "";
                                _selectFaculty(e);
                              }}
                            >
                              <option disabled value="">
                                ເລືອກຄະນະວິຊາ
                              </option>
                              {faculties
                                ?.filter((itm) => itm?.group?.includes(group))
                                ?.map((x, idx) => (
                                  <option key={"" + index + idx} value={x.id}>
                                    {x.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </div>
                          <div className="col-sm-5">
                            <Form.Control
                              as="select"
                              name={"mjor" + index}
                              isInvalid={
                                selectRegisters[index].major ? false : true
                              }
                              value={selectRegisters[index].major}
                              onChange={(e) => {
                                if (
                                  selectRegisters.find(
                                    (im) => im.major == e.target.value
                                  )
                                ) {
                                  return e.preventDefault();
                                }
                                selectRegisters[index].major = e.target.value;
                                const found = majors.find(
                                  (im) => im.id == e.target.value
                                );
                                selectRegisters[index].department =
                                  found?.department?.id;
                                selectRegisters[index].departmentName =
                                  found?.department?.name;
                                selectRegisters[index].majorName = found?.name;
                                selectRegisters[index].majorCode = found?.code;
                                _selectMajor(e);
                              }}
                            >
                              <option disabled value="">
                                ເລືອກສາ​ຂາ​ວິ​ຊາ
                              </option>
                              {majors
                                ?.filter(
                                  (itm) =>
                                    itm?.faculty?.id ==
                                      selectRegisters[index].faculty &&
                                    itm?.group == group
                                )
                                ?.map((x, idx) => (
                                  <option key={"" + index + idx} value={x.id}>
                                    {x.name}
                                  </option>
                                ))}
                            </Form.Control>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* <div className='card bg-register'>ເລືອກສະຖານທີ່ຄັດເລືອກ</div>
                <div className="row">
                  <div className='col-xs-12'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ສະຖານທີ່ຄັດເລືອກ <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      as='select'
                      name='registrationAt'
                      onChange={e => setRegistrationAt(e?.target?.value)}
                      isInvalid={registrationAt === ''}
                      value={registrationAt}
                    >
                      <option value='' disabled>
                        ເລືອກສະຖານທີ່ຄັດເລືອກ
                      </option>
                      <option value="MORJOR">ມຈ</option>
                      <option value="SALAVANH">ແຂວງສາລະວັນ</option>
                      <option value="ATTAPEU">ແຂວງອັດຕະປື</option>
                      <option value="SEKONG">ແຂວງເຊກອງ</option>
                    </Form.Control>
                  </div>
                  <div>
                    <br />
                    <p><b>ໝາຍເຫດ: </b>“ຖ້າເລືອກຄັດເລືອກຢູ່ແຂວງໃດ ແມ່ນໃຫ້ໄປລົງທະບຽນຄັດເລືອກເຂົ້າຮຽນຢູ່ແຂວງນັ້ນ”</p>
                  </div>
                </div> */}
                {/* <div className='card bg-register'>ກໍລະນີສຸກເສີນຕິດຕໍ່</div>
                <div className='row'>
                  <div className='col-xs-12 col-md-6 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ພົວ​ພັນ​ທ່ານ
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.contactName}
                      name='contactName'
                      isInvalid={errors.contactName}
                      placeholder='ກະ​ລຸ​ນາ ປ້ອນ​ຊື່ ແລະ ນາມ​ສະ​ກຸນ'
                      onChange={handleChange}
                    />
                  </div>
                  <div className='col-xs-12 col-md-6 mt-2'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ເບີ​ໂທ​ຕິດ​ຕໍ່
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={values.emergencyPhone}
                      name='emergencyPhone'
                      isInvalid={errors.emergencyPhone}
                      placeholder='​ກະ​ລຸ​ນາ ປ້ອນ​ເບີ​ຕິດ​ຕໍ່'
                      onChange={handleChange}
                    />
                  </div>

                  <div className='col-xs-12 col-md-12'>
                    <Form.Group
                      controlId='formBasicCheckbox'
                      style={{ marginTop: 25 }}
                    >
                      <Form.Check
                        type='checkbox'
                        label='ຢູ່ຮ່ວມກັນ'
                        checked={values.isLiveTogether}
                        onChange={handleChange}
                        value={values.isLiveTogether}
                        name='isLiveTogether'
                      />
                    </Form.Group>
                  </div>

                  <div className='col-xs-12 col-md-12 mb-5'>
                    <Form.Label fontSize='14' className='text-bold'>
                      ໝາຍເຫດ ຫາກບໍ່ໄດ້ຢູ່ຮ່ວມກັນ ໃຫ້ລະບຸດ້ານລຸ່ມ
                    </Form.Label>
                    <Form.Control
                      type='text'
                      disabled={values.isLiveTogether}
                      value={values.explainNotToStayTogether}
                      name='explainNotToStayTogether'
                      placeholder='​ກະ​ລຸ​ນາ​ປ້ອນ​ສະ​ຖານ​ທີ່'
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
                <div className="card bg-register">
                  ຂໍ້ມູນຄ່າທຳນຽມສະໝັກຄັດເລືອກ
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <Form.Label fontSize="14" className="text-bold">
                      ປະເພດຊຳລະຄ່າທຳນຽມ:
                    </Form.Label>
                    <input
                      inline
                      style={{ marginLeft: 16 }}
                      label="ເງິນສົດ"
                      type="radio"
                      value="CASH"
                      id="cash-radio"
                      name="payment"
                      checked={payBy === "CASH" ? true : false}
                      onChange={(e) => {
                        setPayBy(e.target.value);
                        setTransferSheetTarget({
                          file: null,
                          previewView: null,
                        });
                      }}
                    />
                    <label htmlFor="cash-radio" style={{ marginLeft: 4 }}>
                      ເງິນສົດ
                    </label>
                    <input
                      inline
                      style={{ marginLeft: 16 }}
                      label="ເງິນໂອນ"
                      type="radio"
                      value="TRANSFER"
                      id="transfer-radio"
                      name="payment"
                      checked={payBy === "TRANSFER" ? true : false}
                      onChange={(e) => setPayBy(e.target.value)}
                    />
                    <label htmlFor="transfer-radio" style={{ marginLeft: 4 }}>
                      ເງິນໂອນ
                    </label>
                  </div>
                  {payBy === "TRANSFER" ? (
                    <>
                      <div className="col-xs-12 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          <span className="text-danger">ໝາຍເຫດ *: </span>{" "}
                          ກໍລະນີຈ່າຍເງິນໂອນ ໃຫ້ໃສ່ລາຍລະອຽດ ຊື່, ນາມສະກຸນ ແລະ
                          ແຂວງ ຂອງຜູ້ສະໝັກໃຫ້ຊັດເຈນ
                        </Form.Label>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {payBy === "TRANSFER" ? (
                    <>
                      <div className="col-xs-12 col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຄ່າທຳນຽມສະໝັກຄັດເລືອກ
                          {/* <span className='text-danger'>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນຄ່າທຳນຽມສະໝັກຄັດເລືອກ"
                          value={values.registerFee.toLocaleString()}
                          // name='registerFee'
                          disabled={true}
                          // isInvalid={errors.highSchoolSummaryScore}
                          // onChange={handleChange}
                        />
                        <Form.Label fontSize="14" className="text-bold">
                          ຮູບຫຼັກຖານໂອນຄ່າທຳນຽມ
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <div
                          style={{
                            width: "100%",
                            maxWidth: "250px",
                          }}
                        >
                          <input
                            type="file"
                            id="transferImage"
                            accept="image/*"
                            name="image"
                            ref={transferImage}
                            style={{ display: "none" }}
                            onChange={_handleChangeTransferImage}
                          />
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ອັບໂຫຼດຫຼັກຖານໂອນເງິນ (ຮູບ)
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="outline-secondary"
                              onClick={_addTransferImage}
                              style={{ width: "100%" }}
                            >
                              <i className="fa fa-download"></i>{" "}
                              ອັບໂຫຼດຫຼັກຖານໂອນເງິນ (ຮູບ)
                            </Button>
                          </OverlayTrigger>
                          <img
                            alt=""
                            src={
                              transferSheetTarget?.previewView
                                ? transferSheetTarget?.previewView
                                : student?.registerFeeImage
                                ? consts.S3_IMAGE + student?.registerFeeImage
                                : "/assets/img-background.jpg"
                            }
                            style={{
                              width: "100%",
                            }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ບັນຊີທະນາຄານຮັບຄ່າທຳນຽມ
                          {/* <span className='text-danger'>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນບັນຊີທະນາຄານຮັບຄ່າທຳນຽມ"
                          value={"0471225678585"}
                          disabled={true}
                          // isInvalid={errors.highSchoolSummaryScore}
                          onChange={handleChange}
                        />
                        <div className="text-center">
                          <img
                            alt=""
                            src={"/bank_qr.jpeg"}
                            style={{
                              width: "100%",
                              maxWidth: 400,
                            }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xs-12 col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ຄ່າທຳນຽມສະໝັກຄັດເລືອກ
                          {/* <span className='text-danger'>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນຄ່າທຳນຽມສະໝັກຄັດເລືອກ"
                          value={values.registerFee.toLocaleString()}
                          // name='registerFee'
                          disabled={true}
                          // isInvalid={errors.highSchoolSummaryScore}
                          // onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-md-6 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          ເລກທີ່ໃບບິນ
                          {/* <span className='text-danger'>*</span> */}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນເລກທີ່ໃບບິນ"
                          value={values.registerBillNo}
                          name="registerBillNo"
                          // disabled={true}
                          isInvalid={errors.registerBillNo}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 mt-2">
                        <Form.Label fontSize="14" className="text-bold">
                          <span className="text-danger">ໝາຍເຫດ *: </span>{" "}
                          ກໍລະນີຈ່າຍເງິນສົດ ໃຫ້ຜູ້ສະໝັກມາຈ່າຍນໍາພະນັກງານລົງທະບຽນ
                          ທີ່ສູນຂໍ້ມູນຂ່າວສານມະຫາວິທະຍາໄລຈໍາປາສັກ ແລະ
                          ປ້ອນເລກທີໃບບິນທີ່ໄດ້ຈາກພະນັກງານລົງທະບຽນເທົ່ານັ້ນ
                        </Form.Label>
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <div
                    className="d-flex justify-content-center fixed-bottom button-submit-registerStudent"
                    style={{ backgroundColor: "#fff", padding: 10 }}
                  >
                    <div className="col-xs-6 col-md-6 marign-button-apply-right">
                      <button
                        className="btn btn-outline-primary btn-lg float-right"
                        title="ຍົກເລີກ"
                        onClick={() => {
                          _handleClickotp();
                        }}
                      >
                        ຍົກເລີກ
                      </button>
                    </div>
                    <div className="col-xs-6 col-md-6 marign-button-apply-left">
                      <button
                        // disabled={selectRegisters.length === 3 ? false : true}
                        style={{ color: "#fff" }}
                        className="btn btn-defualt bg-active btn-lg button-apply"
                        onClick={handleSubmit}
                      >
                        {id ? "ແກ້ໄຂ" : "ສົ່ງຂໍ້ມູນ"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      }

      <Modal style={{ marginTop: 50 }} show={isLoading} size="xl" centered>
        <Modal.Body>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "40vh" }}
          >
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size72 text-bold"
              style={{ color: "#00A1DE" }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
            </div>
          </Col>
        </Modal.Body>
      </Modal>

      <RegistrationConfirm
        param={formParam}
        studentType={studentType}
        id={id}
        onConfirm={() => onCreateStudent()}
        isDisabled={isDisabled}
        showAddConfirmModal={showAddConfirmModal}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
      />
    </div>
  );
}
