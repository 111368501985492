import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Col, Row, Card, } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { formatTime, formatDateDash } from '../../common/super'
import { getUserDataFromLCStorage } from '../../helpers/user'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { STUDENTS } from '../../apollo/students'
import { UPDATE_STUDENT_STATUS } from "../../apollo/registrationstudent";
import { CREATE_PAYMENT } from '../../apollo/payment';


export default function Success() {
  const [paymentData, setPaymentData] = useState()
  const [studentsData, setStudentsData] = useState()
  const code = getUserDataFromLCStorage().code

  const codeId = _.split(code, "/")
  const [dataStudent, { data: student }] = useLazyQuery(STUDENTS, {
    variables: {
      where: { phoneMobile: codeId[0] }
    }
  })
  useEffect(() => {
    dataStudent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (student?.students?.data[0]) {
      setStudentsData(student?.students?.data[0])
      getUrlVars(student?.students?.data[0])

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student])

  const [createPayment] = useMutation(CREATE_PAYMENT)
  // const [updateStudent, { data: studentData }] = useMutation(UPDATE_STUDENT)
  const [updateStudentStatus] = useMutation(UPDATE_STUDENT_STATUS)

  const getUrlVars = async (studentId) => {
    try {
      await createPayment({
        variables: {
          data: {
            cardTypeName: paymentData?.card_type_name,
            reqBillToForename: paymentData?.req_bill_to_forename,
            authAmount: paymentData?.auth_amount,
            reqCurrency: paymentData?.req_currency,
            transactionId: paymentData?.transaction_id,
          }
        }
      });
      updateStudentStatus({
        variables: {
          data: {
            statusStep: "APPROVED"
          },
          where: { phoneMobile: codeId[0] }
        }
      })
    } catch (err) {
      console.log("err", err)
    }
    let _data
    let searchParams = new URLSearchParams(window.location.href)
    for (let p of searchParams) {
      if (p[0].slice(0, 4) !== 'http') {
        _data = {
          ..._data,
          [p[0]]: p[1]
        }
      }
    }
    setPaymentData(_data)
  }
  return (
    // <div style={{ padding: 24 }}>
    //     <h3>Payment Success!</h3>
    //     <div>
    //         <div><b><u>DATA:</u></b></div>
    //         <div>req_card_number: {paymentData?.req_card_number ?? "-"}</div>
    //         <div>req_bill_to_surname: {paymentData?.req_bill_to_surname ?? "-"}</div>
    //         <div>req_bill_to_address_cit: {paymentData?.req_bill_to_address_cit ?? "-"}</div>
    //         <div>req_card_expiry_date: {paymentData?.req_card_expiry_date ?? "-"}</div>
    //         <div>req_bill_to_phone: {paymentData?.req_bill_to_phone ?? "-"}</div>
    //         <div>card_type_name: {paymentData?.card_type_name ?? "-"}</div>
    //         <div>auth_amount: {paymentData?.auth_amount ?? "-"}</div>
    //         <div>req_bill_to_forename: {paymentData?.req_bill_to_forename ?? "-"}</div>
    //         <div>req_payment_method: {paymentData?.req_payment_method ?? "-"}</div>
    //         <div>req_bill_to_email: {paymentData?.req_bill_to_email ?? "-"}</div>
    //         <div>transaction_id: {paymentData?.transaction_id ?? "-"}</div>
    //         <div>req_currency: {paymentData?.req_currency ?? "-"}</div>
    //         <div>req_card_type: {paymentData?.req_card_type ?? "-"}</div>
    //         <div>decision: {paymentData?.decision ?? "-"}</div>
    //         <div>req_bill_to_address_country: {paymentData?.req_bill_to_address_country ?? "-"}</div>
    //         <div>req_bill_to_address_line1: {paymentData?.req_bill_to_address_line1 ?? "-"}</div>
    //         <div>req_bill_to_address_state: {paymentData?.req_bill_to_address_state ?? "-"}</div>
    //     </div>
    <div className='container-fluid' style={{ height: '100vh' }}>
      <Card style={{ border: "none", color: "#6F6F6F" }}>
        <h2 style={{ padding: 20 }}>ເບິ່ງ​ຂໍ້​ມູນ​ທີ່​ຊຳ​ລະ​ແລ້ວ</h2>
        <Row>
          <Col sm='4'></Col>
          <Card style={{ border: "none", width: 590, marginBottom: 40, boxShadow: "0px 3px 6px #00000029" }}>
            <Card.Body>
              <Row>
                <Col sm='12' style={{ color: '#00B033', textAlign: 'center' }}>
                  <FontAwesomeIcon icon={faCheckCircle} size='4x' />
                </Col>
                <Col
                  sm='12'
                  style={{
                    color: '#00B033',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: 23,
                    padding: 12
                  }}
                >
                  ສຳ​ເລັດ​ການ​ຊຳ​ລະ​ເງິນ
                </Col>
                <Col sm='12' style={{ textAlign: 'center' }}>
                  {formatDateDash(studentsData?.payment?.createdAt)}
                </Col>
                <Col sm='12' style={{ textAlign: 'center', padding: 8 }}>
                  {formatTime(studentsData?.payment?.createdAt)}
                </Col>
                <Col
                  sm='12'
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                >
                  ຂໍ້​ມຸນ​ນັກ​ສຶກ​ສາ
                </Col>
                <Row>
                  <Col sm='7'>ລະ​ຫັດ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm='5'>{studentsData?.studentNumber}</Col>
                  <hr />
                  <Col sm='7'>ຊື່ ແລະ ນາມສະກຸນສັກສືກສາ</Col>
                  <Col sm='5'>{studentsData?.firstNameL} {studentsData?.lastNameL}</Col>
                  <hr />
                  <Col sm='7' style={{ marginTop: -8 }}>
                    ເບີໂທ
                  </Col>
                  <Col sm='5' style={{ marginTop: -8 }}>{studentsData?.phoneMobile}</Col>
                </Row>
                <Col
                  sm='12'
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                >
                  ຂໍ້​ມຸນ​ການ​ຊຳ​ລະ​ເງິນ
                </Col>
                <Row>
                  <Col sm='7'>ປະ​ເພດ​ການ​​ຊຳ​ລະ</Col>
                  <Col sm='5'>{paymentData?.card_type_name ?? '-'}</Col>
                  <hr />
                  <Col sm='7'>ຊື່ບັນຊີຜູ້ໂອນ</Col>
                  <Col sm='5'>{paymentData?.req_bill_to_forename ?? '-'}</Col>
                  <hr />
                  {/* <Col sm='7'>ເລກບັນຊີຜູ້ໂອນ</Col>
                  <Col sm='5'>{paymentData?.transaction_id ?? "-"}</Col>
                  <hr /> */}
                  {/* <Col sm='7'>ທະນາຄານ</Col>
                  <Col sm='5'></Col>
                  <hr /> */}
                  <Col sm='7' style={{ fontWeight: 'bold' }}>
                    ຈຳນວນເງີນ
                  </Col>
                  <Col sm='5' style={{ fontWeight: 'bold' }}>
                    {paymentData?.auth_amount ?? '-'}{' '}
                    {paymentData?.req_currency ?? '-'}
                  </Col>
                  <hr />
                  {/* <Col sm='7'>ຄໍາອະທິບາຍ</Col>
                  <Col sm='5' style={{fontSize: 12}}>{student?.payment?.description}</Col>
                  <hr /> */}
                  <Col sm='7'>ເລກໃບບິນ</Col>
                  <Col sm='5'>{paymentData?.transaction_id ?? '-'}</Col>
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Card>
    </div>
  )
}
